import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import toast from "react-hot-toast";
import {
  pendingJobsThunkAction,
  approveJobThunkAction,
  inspectorListThunkAction,
  activeJobsThunkAction
} from "../../redux/Admin/actions";

const ApproveModal = ({ toggleModal, isOpen, id, inspectorId }) => {
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const onApproveJob = () => {
    dispatch(
      pendingJobsThunkAction(
        {
          jobStatus: "pending",
          limit: 5
        },
        onSuccess,
        onError
      )
    );
    dispatch(
      inspectorListThunkAction({
        role: "inspector"
      })
    );
    dispatch(
      activeJobsThunkAction(
        {
          jobStatus: "approved",
          status: "assigned,inprogress",
          limit: 10
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }
  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Approve Job</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalBody>Do you really want to Approve the Job ? Once done Cannot be undone.</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              const requestBody = { jobStatus: "approved", inspectorId: inspectorId };
              dispatch(approveJobThunkAction(id, requestBody, onApproveJob));
              toggleModal();
            }}
          >
            Approve
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ApproveModal;
