import {
  FETCH_CURRENT_JOB,
  FETCH_CURRENT_JOB_SUCCESS,
  FETCH_CURRENT_JOB_FAILURE,
  ADD_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  JOB_DETAILS,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAILURE,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
  UPDATE_JOB_DETAILS
} from "./types";

import {
  addJobApi,
  currentJobPostingApi,
  deleteAdjusterApi,
  deleteJobApi,
  jobDetailsApi,
  updateJobApi
} from "../../services/adjusterServices";
import toast from "react-hot-toast";

export const fetchCurrentJobPosting = () => {
  return {
    type: FETCH_CURRENT_JOB
  };
};

export const fetchCurrentJobPostingSuccess = (currentJobPosting, totalPage) => {
  return {
    type: FETCH_CURRENT_JOB_SUCCESS,
    payload: { currentJobPosting, totalPage }
  };
};

export const fetchCurrentJobPostingFailure = (error) => {
  return {
    type: FETCH_CURRENT_JOB_FAILURE,
    payload: error
  };
};

export const addJob = () => {
  return {
    type: ADD_JOB
  };
};

export const addJobSuccess = () => {
  return {
    type: ADD_JOB_SUCCESS
  };
};

export const addJobFailure = () => {
  return {
    type: ADD_JOB_FAILURE
  };
};

export const deleteJob = () => {
  return {
    type: DELETE_JOB
  };
};

export const deleteJobSuccess = () => {
  return {
    type: DELETE_JOB_SUCCESS
  };
};

export const deleteJobFailure = () => {
  return {
    type: DELETE_JOB_FAILURE
  };
};

export const jobDetails = () => {
  return {
    type: JOB_DETAILS
  };
};

export const jobDetailsSuccess = (jobDetails) => {
  return {
    type: JOB_DETAILS_SUCCESS,
    payload: { jobDetails }
  };
};

export const jobDetailsFailure = (error) => {
  return {
    type: JOB_DETAILS_FAILURE,
    payload: error
  };
};

export const updateJob = () => {
  return {
    type: UPDATE_JOB
  };
};

export const updateJobSuccess = () => {
  return {
    type: UPDATE_JOB_SUCCESS
  };
};

export const updateJobFailure = () => {
  return {
    type: UPDATE_JOB_FAILURE
  };
};

export const updateJobDetails = (type) => {
  return {
    type: UPDATE_JOB_DETAILS,
    payload: type
  };
};

export const fetchCurrentJobThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCurrentJobPosting());
      const { data } = await currentJobPostingApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(
        fetchCurrentJobPostingSuccess(data?.data?.jobs, data?.data?.jobsCount / params?.limit)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchCurrentJobPostingFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const addJobThunkAction = (requestBody, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(addJob());
      const { data } = await addJobApi(requestBody);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(addJobSuccess());
      toast.success("Job Created Successfully.");
      onSuccess();
    } catch (error) {
      dispatch(addJobFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const deleteJobThunkAction = (id, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(deleteJob());
      const { data } = await deleteJobApi(id);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(deleteJobSuccess());
      toast.success("Job Deleted Successfully.");
      onSuccess();
    } catch (error) {
      dispatch(deleteJobFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const JobDetailsThunkAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(jobDetails());
      const { data } = await jobDetailsApi(id);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(jobDetailsSuccess(data?.data));
    } catch (error) {
      dispatch(jobDetailsFailure());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateJobThunkAction = (id, requestBody, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(updateJob());
      const { data } = await updateJobApi(id, requestBody);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(updateJobSuccess());
      toast.success(data?.message);
      onSuccess();
    } catch (error) {
      dispatch(updateJobFailure());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const deleteAdjusterThunkAction = (id, onDelete) => {
  return async () => {
    try {
      const promise = deleteAdjusterApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Adjuster deleted successfully.",
        error: (error) =>
          `${error?.response?.data?.message || error?.message || "Internal server Error."}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onDelete();
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};
