import Swal from "sweetalert2";

export const formatPhoneNumber = (phone) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "+1(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

function confirmationPopup(deleteObject) {
  return Swal.fire({
    html: `<h4 style="font-size:1.2rem;">Sure, you want to delete the ${deleteObject} !?</h4>`,
    confirmButtonColor: "#0063a0",
    showDenyButton: true,
    confirmButtonText: "Delete",
    denyButtonText: `Cancel`
  });
}

export default confirmationPopup;
