import { Button, Badge } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";
import Select from "react-select";
import { useState } from "react";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Icon } from "@iconify/react";

export const getColumns = (
  inspectorList,
  inspector,
  onInspectorUpdated,
  onRejectHandler,
  onApproveHandler,
  onClickViewHandler
) => {
  const inspectorArray = inspectorList.map((item) => {
    return {
      id: item.id,
      value:
        item.id +
        " - " +
        item.fullName +
        " - " +
        (+item.assignedJobsCount + +item.inProgressJobsCount),
      label: item.fullName + " - " + (+item.assignedJobsCount + +item.inProgressJobsCount)
    };
  });

  return [
    {
      Header: "Claim No",
      accessor: "claimNumber"
    },
    {
      Header: "Insured Name",
      accessor: "ownerName"
    },
    {
      Header: "Phone Number",
      accessor: "mobile",
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.mobile)}</span>;
      }
    },
    {
      Header: "Date and Time",
      accessor: "inspectionTime",
      Cell: ({ row }) => {
        const [isEditable, setIsEditable] = useState(false);
        const date =
          moment(row.original.inspectionTime).format("D MMM YYYY") +
          " | " +
          moment(row.original.inspectionTime).format("LT");
        return (
          <>
            {!isEditable ? (
              <span>
                {row.original.inspectionTime ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div>{date}</div>
                    <div>
                      <Icon
                        icon="tabler:edit"
                        className="mx-3"
                        onClick={() => setIsEditable(!isEditable)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    -----
                    <div>
                      <Icon
                        icon="tabler:edit"
                        className="mx-3"
                        onClick={() => setIsEditable(!isEditable)}
                      />
                    </div>
                  </div>
                )}
              </span>
            ) : (
              <DatePicker
                setIsEditable={setIsEditable}
                id={row?.original?.id}
                isActiveJob={true}
                inspectionTime={row?.original?.inspectionTime}
                claimNumber={row?.original?.claimNumber}
              />
            )}
          </>
        );
      }
    },
    {
      Header: "Adjuster Name",
      accessor: "user",
      Cell: ({ row }) => {
        return <span>{row.original.user?.fullName}</span>;
      },
      disableSortBy: true
    },
    {
      Header: "Inspection Type",
      accessor: "type",
      disableSortBy: true
    },
    {
      Header: "Select Inspector",
      accessor: "id",
      Cell: ({ row }) => {
        if (row?.original?.status === "assigned") {
          return (
            <Select
              name={row.original.id}
              id={row.original.id}
              options={inspectorArray}
              placeholder="Select Inspector"
              className="input-select"
              menuPosition="fixed"
              onChange={(e) => {
                onInspectorUpdated(row?.original?.id, e);
              }}
              value={
                inspector.find((isn) => isn?.id === row.original.id)?.value ||
                inspectorArray.filter((item) => {
                  if (item.id === row?.original?.inspector?.id) {
                    return item;
                  }
                })[0]
              }
            />
          );
        } else {
          return <span>{row.original.inspector?.fullName}</span>;
        }
      },
      disableSortBy: true
    },
    {
      Header: "Status",
      disableSortBy: true,
      accessor: "status",
      Cell: ({ row: value }) => {
        if (value.original.status === "assigned") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#FF7A00",
                  backgroundColor: "#FFF2E6",
                  border: "1px solid #BF5C00",
                  borderRadius: "12px"
                }}
              >
                Assigned
              </Badge>
            </div>
          );
        } else if (value.original.status === "inprogress") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#868686",
                  backgroundColor: "#F9F9F9",
                  border: "1px solid #595959",
                  borderRadius: "12px"
                }}
              >
                Inprogress
              </Badge>
            </div>
          );
        } else if (value.original.status === "completed") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#0D59B1",
                  backgroundColor: "#CEE0F4",
                  border: "1px solid #A1CDFF",
                  borderRadius: "12px"
                }}
              >
                Completed
              </Badge>
            </div>
          );
        } else if (value.original.status === "paused") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#0D59B1",
                  backgroundColor: "#CEE0F4",
                  border: "1px solid #A1CDFF",
                  borderRadius: "12px"
                }}
              >
                Paused
              </Badge>
            </div>
          );
        } else {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#FF0000",
                  backgroundColor: "#FFE6E6",
                  border: "1px solid #BF0000",
                  borderRadius: "12px"
                }}
              >
                Cancelled
              </Badge>
            </div>
          );
        }
      }
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        if (row?.original?.status === "assigned") {
          return (
            <>
              <div className="d-flex justify-content-around">
                <Button
                  size="sm"
                  style={{
                    border: "1px solid #FF7A00",
                    borderRadius: "20px",
                    background: " #FF7A00",
                    color: "#ffffff"
                  }}
                  disabled={!row?.original?.status === "assigned"}
                  onClick={() => onRejectHandler(row?.original?.id)}
                >
                  Reject
                </Button>

                <Button
                  size="sm"
                  style={{
                    border: "1px solid #0D59B1",
                    borderRadius: "20px",
                    background: "#0D59B1",
                    color: "#ffffff"
                  }}
                  disabled={
                    !inspector.find((isn) => isn?.id === row.original.id) ||
                    !row?.original?.status === "assigned"
                  }
                  onClick={() => onApproveHandler(row?.original?.id, inspector)}
                >
                  Approve
                </Button>
              </div>
            </>
          );
        } else {
          return <span className="d-flex justify-content-center align-items-center">-----</span>;
        }
      }
    },
    {
      Header: "Details",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              color="primary"
              outline
              style={{ borderRadius: "12px" }}
              onClick={() => onClickViewHandler(row?.original?.id)}
            >
              View
            </Button>
          </div>
        );
      }
    }
  ];
};
