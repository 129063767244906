const userRoles = {
  ADMIN: "admin",
  ADJUSTER: "adjuster"
};

export default userRoles;

export const getUserRoleName = (role) => {
  switch (role) {
    case userRoles.ADMIN:
      return "Admin";
    case userRoles.ADJUSTER:
      return "Adjuster";
    default:
      return "Unknown";
  }
};
