import {
  DASHBOARD_DETAILS,
  DASHBOARD_DETAILS_SUCCESS,
  DASHBOARD_DETAILS_FAILURE,
  PENDING_JOBS,
  PENDING_JOBS_SUCCESS,
  PENDING_JOBS_FAILURE,
  INSPECTOR_LIST,
  INSPECTOR_LIST_SUCCESS,
  INSPECTOR_LIST_FAILURE,
  ADJUSTER_LIST,
  ADJUSTER_LIST_SUCCESS,
  ADJUSTER_LIST_FAILURE,
  REJECT_JOB,
  REJECT_JOB_SUCCESS,
  REJECT_JOB_FAILURE,
  APPROVE_JOB,
  APPROVE_JOB_SUCCESS,
  APPROVE_JOB_FAILURE,
  ACTIVE_JOBS,
  ACTIVE_JOBS_SUCCESS,
  ACTIVE_JOBS_FAILURE,
  JOB_REPORTING,
  JOB_REPORTING_SUCCESS,
  JOB_REPORTING_FAILURE,
  PENDING_JOB_UPDATE,
  ACTIVE_JOB_UPDATE
} from "./types";

const initialState = {
  isLoading: false,
  isLoadings: false,
  isLoadingInspector: false,
  totalPage: 0,
  totalPages: 0,
  inspectorPage: 0,
  error: "",
  errors: "",
  dashboardDetails: [],
  pendingJobs: [],
  activeJobs: [],
  jobReporting: [],
  inspectorList: [],
  adjusterList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_DETAILS:
      return { ...state, isLoading: true };
    case DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardDetails: action.payload.dashboardDetails,
        error: ""
      };
    case DASHBOARD_DETAILS_FAILURE:
      return { ...state, isLoading: false, dashboardDetails: [], error: action.payload };

    case PENDING_JOBS:
      return { ...state, isLoading: true };
    case PENDING_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pendingJobs: action.payload.pendingJobs,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case PENDING_JOBS_FAILURE:
      return { ...state, isLoading: false, pendingJobs: [], error: action.payload };

    case PENDING_JOB_UPDATE:
      return {
        ...state,
        pendingJobs: state.pendingJobs.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              inspectionTime: action.payload.date
            };
          }
          return item;
        })
      };
    case ACTIVE_JOBS:
      return { ...state, isLoadings: true };
    case ACTIVE_JOBS_SUCCESS:
      return {
        ...state,
        isLoadings: false,
        activeJobs: action.payload.activeJobs,
        totalPages: action.payload.totalPages,
        errors: ""
      };
    case ACTIVE_JOBS_FAILURE:
      return { ...state, isLoadings: false, activeJobs: [], errors: action.payload };

    case ACTIVE_JOB_UPDATE:
      return {
        ...state,
        activeJobs: state.activeJobs.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              inspectionTime: action.payload.date
            };
          }
          return item;
        })
      };

    case JOB_REPORTING:
      return { ...state, isLoading: true };
    case JOB_REPORTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobReporting: action.payload.jobReporting,
        totalPage: action.payload.totalPage,
        errors: ""
      };
    case JOB_REPORTING_FAILURE:
      return { ...state, isLoading: false, jobReporting: [], error: action.payload };

    case INSPECTOR_LIST:
      return { ...state, isLoadingInspector: true };
    case INSPECTOR_LIST_SUCCESS:
      return {
        ...state,
        isLoadingInspector: false,
        inspectorList: action.payload.inspectorList,
        inspectorPage: action.payload.inspectorPage,
        error: ""
      };
    case INSPECTOR_LIST_FAILURE:
      return { ...state, isLoadingInspector: false, inspectorList: [], error: action.payload };

    case ADJUSTER_LIST:
      return { ...state, isLoading: true };
    case ADJUSTER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adjusterList: action.payload.adjusterList,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case ADJUSTER_LIST_FAILURE:
      return { ...state, isLoading: false, adjusterList: [], error: action.payload };

    case REJECT_JOB:
      return { ...state };
    case REJECT_JOB_SUCCESS:
      return { ...state };
    case REJECT_JOB_FAILURE:
      return { ...state };

    case APPROVE_JOB:
      return { ...state };
    case APPROVE_JOB_SUCCESS:
      return { ...state };
    case APPROVE_JOB_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default reducer;
