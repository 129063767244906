import React from "react";
import "../../pages/JobPosting/CurrentJobPosting.css";
import UserImage from "../../assets/img/sidebarIcon/user.png";
import { Button, Menu, MenuItem, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LogOutUserThunkAction } from "../../redux/User/actions";
import { Link, useNavigate } from "react-router-dom";
import useViewport from "../../hooks/useViewport";
import userRoles from "../../utils/roles";
import { selectUserRole } from "../../redux/User/selectors";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#0d59b1",
    borderRadius: 6,
    marginTop: theme.spacing(-0.5),
    minWidth: 200,
    color: "white",
    marginLeft: "0px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "&:hover": {
        backgroundColor: "#ffffff",
        color: "#0d59b1"
      }
    }
  }
}));

const Navbar = ({ title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { width } = useViewport();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToLoginPage = () => {
    navigate("/auth/login");
  };

  const signOut = () => {
    dispatch(LogOutUserThunkAction(navigateToLoginPage));
  };

  const changePassword = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const email = JSON.parse(user).email;
    role === "adjuster"
      ? navigate("/adjuster/resetpassword", { state: { email: email, token: token } })
      : navigate("/admin/resetpassword", { state: { email: email, token: token } });
  };

  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const profilePageRoute = () => {
    switch (role) {
      case userRoles.ADJUSTER:
        return "/adjuster/profile";
      default:
        return "/nofound";
    }
  };

  return (
    <>
      {width > 786 ? (
        <div className="d-flex justify-content-between">
          <div className="page-title">{title}</div>
          <div>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              className="navbar-dropdown-btn"
              style={{
                backgroundColor: "#f9f9f9",
                color: "black"
              }}
            >
              <img src={UserImage} alt="User Image" />
            </Button>
          </div>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button"
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Link
              to={profilePageRoute()}
              style={{
                textDecoration: "none",
                color: "#ffffff"
              }}
            >
              {role === "adjuster" ? <MenuItem onClick={handleClose}>Profile</MenuItem> : ""}
            </Link>

            <MenuItem
              onClick={() => {
                changePassword();
                handleClose();
              }}
            >
              Change Password
            </MenuItem>

            <MenuItem
              onClick={() => {
                signOut();
                handleClose();
              }}
              disableRipple
            >
              Log out
            </MenuItem>
          </StyledMenu>
        </div>
      ) : (
        <>
          <div className="text-end" style={{ marginTop: "-5px" }}>
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                className="navbar-dropdown-btn"
                style={{
                  backgroundColor: "#f9f9f9",
                  color: "black"
                }}
              >
                <img src={UserImage} alt="User Image" />
              </Button>
            </div>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Link
                to={profilePageRoute()}
                style={{
                  textDecoration: "none",
                  color: "#ffffff"
                }}
              >
                {role === "adjuster" ? <MenuItem onClick={handleClose}>Profile</MenuItem> : ""}
              </Link>
              <Link
                to="/auth/resetpassword"
                style={{
                  textDecoration: "none",
                  color: "#ffffff"
                }}
              >
                <MenuItem onClick={handleClose}>Change Password</MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  signOut();
                  handleClose();
                }}
                disableRipple
              >
                Log out
              </MenuItem>
            </StyledMenu>
          </div>
          <div className="page-title">{title}</div>
        </>
      )}
    </>
  );
};

export default Navbar;
