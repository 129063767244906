export const DASHBOARD_DETAILS = "DASHBOARD_DETAILS";
export const DASHBOARD_DETAILS_SUCCESS = "DASHBOARD_DETAILS_SUCCESS";
export const DASHBOARD_DETAILS_FAILURE = "DASHBOARD_DETAILS_FAILURE";

export const PENDING_JOBS = "PENDING_JOBS";
export const PENDING_JOBS_SUCCESS = "PENDING_JOBS_SUCCESS";
export const PENDING_JOBS_FAILURE = "PENDING_JOBS_FAILURE";
export const PENDING_JOB_UPDATE = "PENDING_JOB_UPDATE";

export const ACTIVE_JOBS = "ACTIVE_JOBS";
export const ACTIVE_JOBS_SUCCESS = "ACTIVE_JOBS_SUCCESS";
export const ACTIVE_JOBS_FAILURE = "ACTIVE_JOBS_FAILURE";
export const ACTIVE_JOB_UPDATE = "ACTIVE_JOB_UPDATE";

export const JOB_REPORTING = "JOB_REPORTING";
export const JOB_REPORTING_SUCCESS = "JOB_REPORTING_SUCCESS";
export const JOB_REPORTING_FAILURE = "JOB_REPORTING_FAILURE";

export const INSPECTOR_LIST = "INSPECTOR_LIST";
export const INSPECTOR_LIST_SUCCESS = "INSPECTOR_LIST_SUCCESS";
export const INSPECTOR_LIST_FAILURE = "INSPECTOR_LIST_FAILURE";

export const ADJUSTER_LIST = "ADJUSTER_LIST";
export const ADJUSTER_LIST_SUCCESS = "ADJUSTER_LIST_SUCCESS";
export const ADJUSTER_LIST_FAILURE = "ADJUSTER_LIST_FAILURE";

export const REJECT_JOB = "REJECT_JOB";
export const REJECT_JOB_SUCCESS = "REJECT_JOB_SUCCESS";
export const REJECT_JOB_FAILURE = "REJECT_JOB_FAILURE";

export const APPROVE_JOB = "REJECT_JOB";
export const APPROVE_JOB_SUCCESS = "REJECT_JOB_SUCCESS";
export const APPROVE_JOB_FAILURE = "REJECT_JOB_FAILURE";
