import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import RestrictedAuth from "../components/RestrictedAuth";
import NewPassword from "../pages/ForgotPassword/NewPassword";
import RecoverPassword from "../pages/ForgotPassword/RecoverPassword";
import Login from "../pages/Login/Login";
import AdjusterRegisteration from "../pages/Register/AdjusterRegisteration";
import VerifyOtp from "../pages/Register/VerifyOtp";

const AuthLayout = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route element={<RestrictedAuth restricted={true} />}>
        <Route path="login" element={<Login />} />
        <Route path="recoverpassword" element={<RecoverPassword />} />
        <Route path="resetpassword" element={<NewPassword />} />
        <Route path="register" element={<AdjusterRegisteration />} />
        <Route path="verifyOtp" element={<VerifyOtp />} />
        <Route
          path="*"
          element={<Navigate to={`/auth/login`} replace state={{ from: location }} />}
        />
      </Route>
    </Routes>
  );
};

export default AuthLayout;
