import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row, Table } from "reactstrap";
import Search from "../../components/Search/Search";
import useViewport from "../../hooks/useViewport";
import { adjusterListThunkAction } from "../../redux/Admin/actions";
import { selectAdmin } from "../../redux/Admin/selectors";
import { selectUserToken } from "../../redux/User/selectors";
import { getColumns } from "./AdjusterList";
import { usePagination, useSortBy, useGlobalFilter } from "react-table";
import { useTable } from "react-table";
import Paginate from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import confirmationPopup from "../../utils/helper";
import { deleteAdjusterThunkAction } from "../../redux/Adjuster/actions";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const Adjuster = () => {
  const [error, setError] = React.useState(null);
  const [adjuster, setAdjuster] = React.useState([]);
  const [sort, setSort] = React.useState({ sortBy: "createdAt", sortDirection: "desc" });
  const clearSearch = React.useRef(null);

  const { width } = useViewport();
  const dispatch = useDispatch();

  const showPage = [5, 10, 20, 30, 50];

  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const { isLoading, adjusterList, totalPage } = useSelector(selectAdmin);

  useEffect(() => {
    setAdjuster(adjusterList);
  }, [adjusterList, adjusterList?.length]);

  const columns = React.useMemo(() => getColumns(deleteAdjusterHandler), []);
  const data = React.useMemo(() => adjuster, [adjuster]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  useEffect(() => {
    if (token) {
      dispatch(
        adjusterListThunkAction({
          search: globalFilter || "",
          limit: pageSize,
          page: pageIndex,
          sort: sort?.sortBy,
          orderBy: sort?.sortDirection,
          role: "adjuster"
        })
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort]);

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  function callAdjusterThunkHandler() {
    dispatch(
      adjusterListThunkAction({
        search: globalFilter || "",
        limit: pageSize,
        page: pageIndex,
        sort: sort?.sortBy,
        orderBy: sort?.sortDirection,
        role: "adjuster"
      })
    );
  }

  async function deleteAdjusterHandler(id) {
    try {
      const result = await confirmationPopup("adjuster");

      if (result.isConfirmed) {
        dispatch(
          deleteAdjusterThunkAction(id, () => {
            if (adjuster?.length === 1 && pageIndex !== 0) {
              gotoPage(pageIndex - 1);
            } else {
              callAdjusterThunkHandler();
            }
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Adjuster List"} />
      <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
        <Col xs="12" md="9" className="d-flex align-items-center">
          <span style={{ fontWeight: "600" }}>Show</span>
          <Input
            type="select"
            style={{
              appearance: "auto",
              width: "8rem",
              margin: "10px",
              cursor: "pointer"
            }}
            className="input-white"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {showPage.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </Col>

        <Col xs="12" md="3">
          <Search
            keyword={globalFilter}
            onSubmitHandler={onSubmitHandler}
            setKeyword={setGlobalFilter}
            clearSearch={clearSearch}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        {isLoading ? (
          <React.Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div className="post_error_loading">{error}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table
                  {...getTableProps()}
                  className="align-items-center table-flush Firms_table_container"
                  responsive
                  bordered
                >
                  <thead className="thead-light Firms_table_header">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((column) => (
                          <th
                            key={column.id}
                            style={!column.disableSortBy ? { cursor: "pointer" } : {}}
                            onClick={() => {
                              if (!column.disableSortBy) {
                                const desc =
                                  column.isSortedDesc === true
                                    ? undefined
                                    : column.isSortedDesc === false
                                    ? true
                                    : false;

                                setSort({
                                  sortBy: column.id,
                                  sortDirection: desc ? "asc" : "desc"
                                });

                                setSortBy([{ id: column.id, desc }]);
                              }
                            }}
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="bi bi-arrow-up mx-2"></i>
                                ) : (
                                  <i className="bi bi-arrow-down mx-2"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell) => {
                            return (
                              <td key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!error && adjuster?.length === 0 && (
                  <div className="post_error_loading">No Inspectors Found</div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {isLoading === false && error === null ? (
          <nav aria-label="..." className="post_footer">
            <Paginate
              pageIndex={pageIndex}
              pageCount={pageCount}
              previousPage={previousPage}
              nextPage={nextPage}
              gotoPage={gotoPage}
            />
          </nav>
        ) : null}
      </Row>
    </div>
  );
};

export default Adjuster;
