import React from "react";
import { Button, FormGroup, Input, InputGroup } from "reactstrap";
import useViewport from "../../hooks/useViewport";
import { useFormik } from "formik";
import * as Yup from "yup";
import { selectUser } from "../../redux/User/selectors";
import { useDispatch, useSelector } from "react-redux";

import LoginImage from "../../assets/img/backgroundImages/loginImage.png";
import SherlockLogo from "../../assets/img/logo/sherlockHome.png";

import "../Login/Login.css";
import { Link, NavLink } from "react-router-dom";
import { forgotPasswordThunkAction } from "../../redux/User/actions";
import { env } from "../../env";

const RecoverPassword = () => {
  const { width } = useViewport();

  const { isSigning } = useSelector(selectUser);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Email Address is invalid.").required("Email is Required.")
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        forgotPasswordThunkAction({
          email: values.email,
          redirectUrl: env.REACT_APP_DEV_FRONTEND_URL + "auth/resetPassword"
        })
      );
      resetForm();
    }
  });

  return (
    <div className={width > 786 ? "p-5" : ""}>
      <div className="limiter">
        <div className="container-login">
          <div className="wrap-login">
            <form className="login-form" autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="py-5">
                <div className="text-center px-2">
                  <img src={SherlockLogo} alt="Sherlock Logo" width="30%" />
                  <div className="text-center d-flex flex-column mx-3">
                    <span style={{ fontSize: "24px" }} className="text-logo mt-3">
                      Catastrophe Inspection
                    </span>
                  </div>
                </div>
                <div style={{ fontSize: "20px" }} className="text-welcome text-center py-4">
                  Recover Password
                </div>
                <div
                  style={{ marginTop: "25px" }}
                  className={width > 500 ? "py-2 mx-5" : "py-2 mx-2"}
                >
                  <FormGroup className="mb-3">
                    <label className="py-3 labelInput">Email Address*</label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="text"
                        className="form-control form-controlInput"
                        placeholder="Enter Your Email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </InputGroup>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger mt-2">{formik.errors.email}</div>
                    ) : null}
                  </FormGroup>
                </div>

                <div className={width > 500 ? "text-center mx-5" : "text-center mx-2"}>
                  <Button
                    disabled={!formik.isValid || isSigning}
                    className="loginButton mt-3 w-100"
                    type="submit"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      {isSigning ? "Sending Email..." : "Reset Password"}
                    </div>
                  </Button>
                </div>
                <div className="d-flex justify-content-center">
                  <div
                    style={{
                      marginTop: "25px",
                      color: "white",
                      fontSize: "17px",
                      fontWeight: "600"
                    }}
                  >
                    Already have an account?
                  </div>
                  <NavLink to="/auth/login" tag={Link}>
                    <div className="mx-2 forgot-password">Log In</div>
                  </NavLink>
                </div>
              </div>
            </form>
            <div className="login-more" style={{ backgroundImage: `url(${LoginImage})` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
