import React, { useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import Navbar from "../../components/Navbar/Navbar";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import SherlockLogo from "../../assets/img/logo/jobImage.png";
import "./JobDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { selectAdjuster } from "../../redux/Adjuster/selectors";
import { useNavigate, useParams } from "react-router-dom";
import { JobDetailsThunkAction } from "../../redux/Adjuster/actions";
import Loader from "../../components/Loader/Loader";
import { formatPhoneNumber } from "../../utils/helper";
import DeleteJobModal from "../JobPosting/DeleteJobModal";
import moment from "moment";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const CancelledJobDetails = () => {
  const { width } = useViewport();
  const { isLoading, jobDetails } = useSelector(selectAdjuster);

  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [lightbox, setLightbox] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");

  const { id: jobId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(JobDetailsThunkAction(jobId));
  }, []);

  const redirectBack = () => {
    if (role === "adjuster") {
      navigate("/adjuster/current-job-posting");
    } else if (role === "admin") {
      navigate("/admin/job-list");
    }
  };

  const role = localStorage.getItem("role");

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Cancelled Job Details"} />
      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : width > 947 ? (
        <Card className="mt-4" style={{ background: "#ffffff" }}>
          {lightbox === true ? (
            <span className="absolute top-0 left-0 h-screen w-screen">
              <Lightbox
                image={imageUrl}
                onClose={() => {
                  setLightbox(false);
                }}
              />
            </span>
          ) : (
            <span className=""></span>
          )}
          <CardBody>
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <div className="d-flex">
                <div style={{ width: "50%" }}>
                  <Col
                    xs="12"
                    lg="12"
                    md="12"
                    className="mb-4"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <Row className="imageStyles" style={{ position: "relative" }}>
                      <img
                        className="my-2"
                        src={jobDetails?.imageUrl ? jobDetails?.imageUrl : `${SherlockLogo}`}
                      />
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Start Date and Time
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {moment(jobDetails?.inspectionTime).format("D MMM YYYY") +
                          " | " +
                          moment(jobDetails?.inspectionTime).format("LT")}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Job Title
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.title}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Claim Number
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.claimNumber}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Insured name
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.ownerName}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Phone number
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {formatPhoneNumber(jobDetails?.mobile)}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        House / Street
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        <div className="my-3">{jobDetails?.address}</div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        City / Zipcode
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.city + ", " + jobDetails?.zipCode}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        State
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.state}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Adjuster Name
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.user?.fullName}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Type of Inspection
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.type}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex  align-items-center label" xs="5" md="5" lg="5">
                        Inspection Needed
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.inspection}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Special Equipments
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.specialEqipment}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        What’s going on with the house?
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.whatsWrong}
                      </Col>
                    </Row>
                  </Col>
                </div>
                <div
                  style={{
                    borderLeft: "2px solid #D1D1D1",
                    height: "100%",
                    marginLeft: "50px",
                    width: "50%"
                  }}
                >
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="inspection" style={{ paddingLeft: "30px" }}>
                        Can’t Run Inspection
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Requested/ Initiated By
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.cancelInspection?.section[0]?.res}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Reason
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.cancelInspection?.section[1]?.res}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex  align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        When was it canceled
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.cancelInspection?.section[3]?.res}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Time Notified
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {moment(jobDetails?.cancelInspection?.section[4]?.res).format(
                          "D MMM YYYY"
                        ) +
                          " | " +
                          moment(jobDetails?.cancelInspection?.section[4]?.res).format("LT")}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Needs to be Rescheduled
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.cancelInspection?.section[6]?.res}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Description
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.cancelInspection?.section[2]?.res}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Front of Property Photo
                      </Col>
                    </Row>
                    <div className="d-flex">
                      {jobDetails?.cancelInspection?.section[7]?.res?.imageNotes.length > 0 ? (
                        jobDetails?.cancelInspection?.section[7]?.res?.imageNotes.map(
                          (item, index) => (
                            <Row
                              onClick={() => {
                                setImageUrl(item.image.path);
                                setLightbox(true);
                              }}
                              key={item.image.path}
                              className="py-3 photos"
                              style={
                                index === 0
                                  ? {
                                      position: "relative",
                                      marginTop: "20px",
                                      marginLeft: "15px",
                                      cursor: "pointer"
                                    }
                                  : {
                                      position: "relative",
                                      marginTop: "20px",
                                      marginLeft: "25px",
                                      cursor: "pointer"
                                    }
                              }
                            >
                              <img src={item.image.path} alt="Property Photos" />
                            </Row>
                          )
                        )
                      ) : (
                        <Col style={{ paddingLeft: "30px" }} className="value" xs="6" md="6" lg="6">
                          Not Available
                        </Col>
                      )}
                    </div>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Condition Photo
                      </Col>
                    </Row>
                    <div className="d-flex">
                      {jobDetails?.cancelInspection?.section[8]?.res?.imageNotes?.length > 0 ? (
                        jobDetails?.cancelInspection?.section[8]?.res?.imageNotes?.map(
                          (item, index) => (
                            <Row
                              onClick={() => {
                                setImageUrl(item.image.path);
                                setLightbox(true);
                              }}
                              key={item.image.path}
                              className="py-3 photos"
                              style={
                                index === 0
                                  ? {
                                      position: "relative",
                                      marginTop: "20px",
                                      marginLeft: "15px",
                                      cursor: "pointer"
                                    }
                                  : {
                                      position: "relative",
                                      marginTop: "20px",
                                      marginLeft: "25px",
                                      cursor: "pointer"
                                    }
                              }
                            >
                              <img src={item.image.path} alt="Condition Photos" />
                            </Row>
                          )
                        )
                      ) : (
                        <Col style={{ paddingLeft: "30px" }} className="value" xs="6" md="6" lg="6">
                          Not Available
                        </Col>
                      )}
                    </div>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Scope Sheet Photo
                      </Col>
                    </Row>
                    <div className="d-flex">
                      {jobDetails?.cancelInspection?.section[9]?.res?.imageNotes?.length > 0 ? (
                        jobDetails?.cancelInspection?.section[9]?.res?.imageNotes?.map(
                          (item, index) => (
                            <Row
                              onClick={() => {
                                setImageUrl(item.image.path);
                                setLightbox(true);
                              }}
                              key={item.image.path}
                              className="py-3 photos"
                              style={
                                index === 0
                                  ? {
                                      position: "relative",
                                      marginTop: "20px",
                                      marginLeft: "15px",
                                      cursor: "pointer"
                                    }
                                  : {
                                      position: "relative",
                                      marginTop: "20px",
                                      marginLeft: "25px",
                                      cursor: "pointer"
                                    }
                              }
                            >
                              <img src={item.image.path} alt="ScopeSheet Photos" />
                            </Row>
                          )
                        )
                      ) : (
                        <Col style={{ paddingLeft: "30px" }} className="value" xs="6" md="6" lg="6">
                          Not Available
                        </Col>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card className="mt-4" style={{ background: "#ffffff" }}>
          {lightbox === true ? (
            <span className="absolute top-0 left-0 h-screen w-screen">
              <Lightbox
                image={imageUrl}
                onClose={() => {
                  setLightbox(false);
                }}
              />
            </span>
          ) : (
            <span className=""></span>
          )}
          <CardBody>
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col xs="12" lg="12" md="12" className="mb-4" style={{ textAlign: "-webkit-center" }}>
                <Row className="imageStyles" style={{ position: "relative" }}>
                  <img className="my-2" src={`${SherlockLogo}`} />
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Job Title
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.title}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Claim Number
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.claimNumber}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex  align-items-center label" xs="5" md="5" lg="5">
                    Insured name
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.ownerName}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Start Date and Time
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {moment(jobDetails?.inspectionTime).format("D MMM YYYY") +
                      " | " +
                      moment(jobDetails?.inspectionTime).format("LT")}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Phone number
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {formatPhoneNumber(jobDetails?.mobile)}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    House / Street
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.address}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    City / Zipcode
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.city + ", " + jobDetails?.zipCode}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    State
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.state}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Adjuster Name
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.user?.fullName}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Type of Inspection
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    <div className="my-3">{jobDetails?.type}</div>
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Inspection Needed
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.inspection}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Special Equipments
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.specialEqipment}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    What’s going on with the house?
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.whatsWrong}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="inspection">Can’t Run Inspection</Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Requested/ Initiated By
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.cancelInspection?.section[0]?.res}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Reason
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.cancelInspection?.section[1]?.res}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex  align-items-center label" xs="5" md="5" lg="5">
                    When was it canceled
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.cancelInspection?.section[3]?.res}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Time Notified
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {moment(jobDetails?.cancelInspection?.section[4]?.res).format("D MMM YYYY") +
                      " | " +
                      moment(jobDetails?.cancelInspection?.section[4]?.res).format("LT")}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Needs to be Rescheduled
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.cancelInspection?.section[6]?.res}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Description
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.cancelInspection?.section[2]?.res}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Front of Property Photo
                  </Col>
                </Row>
                <div className="d-flex">
                  {jobDetails?.cancelInspection?.section[7]?.res?.imageNotes?.length > 0 ? (
                    jobDetails?.cancelInspection?.section[7]?.res?.imageNotes?.map(
                      (item, index) => (
                        <Row
                          onClick={() => {
                            setImageUrl(item.image.path);
                            setLightbox(true);
                          }}
                          key={item.image.path}
                          className="py-3 photos"
                          style={
                            index === 0
                              ? {
                                  position: "relative",
                                  marginTop: "20px",
                                  marginLeft: "0px",
                                  cursor: "pointer"
                                }
                              : {
                                  position: "relative",
                                  marginTop: "20px",
                                  marginLeft: "25px",
                                  cursor: "pointer"
                                }
                          }
                        >
                          <img src={item.image.path} alt="Property Photos" />
                        </Row>
                      )
                    )
                  ) : (
                    <Col className="value" xs="6" md="6" lg="6">
                      Not Available
                    </Col>
                  )}
                </div>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Condition Photo
                  </Col>
                </Row>
                <div className="d-flex">
                  {jobDetails?.cancelInspection?.section[8]?.res?.imageNotes?.length > 0 ? (
                    jobDetails?.cancelInspection?.section[8]?.res?.imageNotes?.map(
                      (item, index) => (
                        <Row
                          onClick={() => {
                            setImageUrl(item.image.path);
                            setLightbox(true);
                          }}
                          key={item.image.path}
                          className="py-3 photos"
                          style={
                            index === 0
                              ? {
                                  position: "relative",
                                  marginTop: "20px",
                                  marginLeft: "0px",
                                  cursor: "pointer"
                                }
                              : {
                                  position: "relative",
                                  marginTop: "20px",
                                  marginLeft: "25px",
                                  cursor: "pointer"
                                }
                          }
                        >
                          <img src={item.image.path} alt="Condition Photos" />
                        </Row>
                      )
                    )
                  ) : (
                    <Col className="value" xs="6" md="6" lg="6">
                      Not Available
                    </Col>
                  )}
                </div>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Scope Sheet Photo
                  </Col>
                </Row>
                <div className="d-flex">
                  {jobDetails?.cancelInspection?.section[9]?.res?.imageNotes?.length > 0 ? (
                    jobDetails?.cancelInspection?.section[9]?.res?.imageNotes?.map(
                      (item, index) => (
                        <Row
                          onClick={() => {
                            setImageUrl(item.image.path);
                            setLightbox(true);
                          }}
                          key={item.image.path}
                          className="py-3 photos"
                          style={
                            index === 0
                              ? {
                                  position: "relative",
                                  marginTop: "20px",
                                  marginLeft: "0px",
                                  cursor: "pointer"
                                }
                              : {
                                  position: "relative",
                                  marginTop: "20px",
                                  marginLeft: "25px",
                                  cursor: "pointer"
                                }
                          }
                        >
                          <img src={item.image.path} alt="ScopeSheet Photos" />
                        </Row>
                      )
                    )
                  ) : (
                    <Col className="value" xs="6" md="6" lg="6">
                      Not Available
                    </Col>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {!isLoading ? (
        <Card className="mt-2" style={{ background: "#FFFFFF" }}>
          <CardBody>
            <div className="d-flex">
              <Button
                size={width > 786 ? "md" : "sm"}
                className="deleteButton"
                onClick={() => {
                  setDeleteModalShow(true);
                }}
              >
                Delete Job
              </Button>

              <Button
                size={width > 786 ? "md" : "sm"}
                className="mx-4 registerButton"
                onClick={redirectBack}
              >
                Back to Job List
              </Button>
            </div>
          </CardBody>
        </Card>
      ) : (
        ""
      )}

      <DeleteJobModal
        isOpen={deleteModalShow}
        toggleModal={() => setDeleteModalShow(false)}
        id={jobId}
        navigate={true}
      />
    </div>
  );
};

export default CancelledJobDetails;
