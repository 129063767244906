export const FETCH_CURRENT_JOB = "FETCH_CURRENT_JOB";
export const FETCH_CURRENT_JOB_SUCCESS = "FETCH_CURRENT_JOB_SUCCESS";
export const FETCH_CURRENT_JOB_FAILURE = "FETCH_CURRENT_JOB_FAILURE";

export const ADD_JOB = "ADD_JOB";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAILURE = "ADD_JOB_FAILURE";

export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const JOB_DETAILS = "JOB_DETAILS";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_DETAILS_FAILURE = "JOB_DETAILS_FAILURE";

export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCES";
export const UPDATE_JOB_FAILURE = "UPDATE_JOB_FAILURE";

export const UPDATE_JOB_DETAILS = "UPDATE_JOB_DETAILS";
