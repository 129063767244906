import axiosInstance from "./axiosInstance";

export const getDashboardDetailsApi = () => axiosInstance.get("/api/users/dashboard");

export const jobListApi = (requestBody) => axiosInstance.post(`api/job/list/all`, requestBody);

export const usersListApi = (requestBody) => axiosInstance.post("api/users/list", requestBody);

export const updateJobStatusApi = (id, requestBody) =>
  axiosInstance.post(`api/job/update/jobStatus/${id}`, requestBody);
