import AddJob from "../assets/img/sidebarIcon/AddJob.png";
import ActiveAddJob from "../assets/img/sidebarIcon/ActiveAddJob.png";
import Inspector from "../assets/img/sidebarIcon/Inspector.png";
import ActiveInspector from "../assets/img/sidebarIcon/ActiveInspector.png";
import JobList from "../assets/img/sidebarIcon/JobList.png";
import ActiveJobList from "../assets/img/sidebarIcon/ActiveJobList.png";
import Dashboard from "../assets/img/sidebarIcon/dashboard.png";
import ActiveDashboard from "../assets/img/sidebarIcon/activeDashboard.png";
import ManageStaff from "../assets/img/sidebarIcon/manageStaff.png";
import ActiveManageStaff from "../assets/img/sidebarIcon/activeManageStaff.png";
import JobReporting from "../assets/img/sidebarIcon/jobReporting.png";
import ActiveJobReporting from "../assets/img/sidebarIcon/activeJobReporting.png";
import Adjuster from "../assets/img/sidebarIcon/adjuster.png";
import ActiveAdjuster from "../assets/img/sidebarIcon/activeAdjuster.png";

const adminSidebarRoutes = [
  {
    title: "Dashboard",
    active: ActiveDashboard,
    inActive: Dashboard,
    path: "/admin/dashboard"
  },
  {
    title: "Manage Staff",
    active: ActiveManageStaff,
    inActive: ManageStaff,
    path: "/admin/manage-staff",
    childrens: [
      {
        title: "Inspector List",
        active: ActiveInspector,
        inActive: Inspector,
        path: "/admin/manage-staff/inspector-list"
      },
      {
        title: "Adjuster List",
        active: ActiveAdjuster,
        inActive: Adjuster,
        path: "/admin/manage-staff/adjuster-list"
      }
    ]
  },
  {
    title: "View Job List",
    active: ActiveJobList,
    inActive: JobList,
    path: "/admin/job-list"
  },
  {
    title: "Job Reporting",
    active: ActiveJobReporting,
    inActive: JobReporting,
    path: "/admin/job-reporting"
  }
];

const adjusterSidebarRoutes = [
  {
    title: "Current Job Posting",
    active: ActiveJobList,
    inActive: JobList,
    path: "/adjuster/current-job-posting"
  },
  {
    title: "Post New Job",
    active: ActiveAddJob,
    inActive: AddJob,
    path: "/adjuster/post-new-job"
  },
  {
    title: "Inspector Reporting",
    active: ActiveInspector,
    inActive: Inspector,
    path: "/adjuster/inspector-reporting"
  }
];

export { adjusterSidebarRoutes };

export default adminSidebarRoutes;
