import { combineReducers } from "redux";
import userReducer from "./User/reducer";
import adjusterReducer from "./Adjuster/reducer";
import adminReducer from "./Admin/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  adjuster: adjusterReducer,
  admin: adminReducer
});

export default rootReducer;
