import React from "react";
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import usePasswordShow from "../../hooks/usePasswordShow";
import useViewport from "../../hooks/useViewport";
import { useFormik } from "formik";
import * as Yup from "yup";
import { selectUser } from "../../redux/User/selectors";
import { useDispatch, useSelector } from "react-redux";

import LoginImage from "../../assets/img/backgroundImages/loginImage.png";
import SherlockLogo from "../../assets/img/logo/sherlockHome.png";
import showPasswordIcon from "../../assets/img/theme/showPassword.png";
import hidePasswordIcon from "../../assets/img/theme/hidePassword.png";

import "../Login/Login.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LoginUserThunkAction } from "../../redux/User/actions";

const Login = () => {
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();
  const { width } = useViewport();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = React.useState("");

  const { isSigning } = useSelector(selectUser);

  const redirectSignUp = () => {
    navigate("/auth/register");
  };
  const redirectToVerify = () => {
    navigate("/auth/verifyOtp", { state: { email: email } });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Email Address is invalid.").required("Email is Required."),
      password: Yup.string().required("Password is Required.")
    }),
    onSubmit: (values) => {
      const requestBody = {
        email: values.email,
        password: values.password
      };
      dispatch(LoginUserThunkAction(requestBody, redirectToVerify));
    }
  });

  return (
    <div className={width > 786 ? "p-5" : ""}>
      <div className="limiter">
        <div className="container-login">
          <div className="wrap-login">
            <form className="login-form" autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="py-5">
                <div
                  className={
                    width > 500
                      ? "d-flex justify-content-center px-2"
                      : "justify-content-center px-2"
                  }
                >
                  <img
                    src={SherlockLogo}
                    alt="Sherlock Logo"
                    width="30%"
                    className={width > 500 ? "" : "mx-auto d-block"}
                  />
                </div>
                <div className="text-welcome text-center py-4">Welcome Back!</div>
                <div className={width > 500 ? "py-2 mx-5" : "py-2 mx-2"}>
                  <FormGroup className="mb-3">
                    <label className="py-3 labelInput">Email Address*</label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="text"
                        className="form-control form-controlInput"
                        placeholder="Enter Your Email"
                        id="email"
                        name="email"
                        onChange={(e) => {
                          formik.setFieldValue("email", e.target.value);
                          setEmail(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </InputGroup>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger mt-2">{formik.errors.email}</div>
                    ) : null}
                  </FormGroup>
                </div>

                <div className={width > 500 ? "mx-5" : "mx-2"}>
                  <FormGroup className="mb-3">
                    <label className="pb-3 labelInput">Password*</label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control containerNumberinput"
                        placeholder="Enter Your Password"
                        type={type}
                        autoComplete="password"
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />

                      <InputGroupAddon
                        addonType="append"
                        style={{ cursor: "pointer", opacity: "0.9" }}
                        onClick={togglePasswordVisiblity}
                      >
                        <InputGroupText className="password_icon">
                          {passwordShown ? (
                            <img
                              style={{ height: "20px" }}
                              alt="hidepassword"
                              src={hidePasswordIcon}
                            />
                          ) : (
                            <img
                              style={{ height: "20px" }}
                              alt="showpassword"
                              src={showPasswordIcon}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger mt-2">{formik.errors.password}</div>
                    ) : null}
                  </FormGroup>
                </div>
                <div className="text-center">
                  <NavLink to="/auth/recoverpassword" tag={Link}>
                    <small className="forgot-password">Forgot Password?</small>
                  </NavLink>
                </div>

                <div className={width > 500 ? "text-center mx-5" : "text-center mx-2"}>
                  <Button
                    disabled={!formik.isValid || isSigning}
                    className="loginButton mt-5 w-100"
                    type="submit"
                  >
                    <div style={{ fontWeight: "bold" }}>{isSigning ? "Logging In" : "Log In"}</div>
                  </Button>
                </div>

                <div className={width > 500 ? "text-center mx-5" : "text-center mx-2"}>
                  <Button className="signUpButton mt-4 w-100" onClick={redirectSignUp}>
                    <div style={{ fontWeight: "bold" }}>Sign Up</div>
                  </Button>
                </div>
              </div>
            </form>
            <div className="login-more" style={{ backgroundImage: `url(${LoginImage})` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
