import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, InputGroup } from "reactstrap";
import useViewport from "../../hooks/useViewport";
import Countdown from "react-countdown";

import RegisterImage from "../../assets/img/backgroundImages/registerImage.png";
import SherlockLogo from "../../assets/img/logo/sherlockHome.png";

import "../Register/AdjusterRegistration.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/User/selectors";
import { sendOtpThunkAction, verifyOtpThunkAction } from "../../redux/User/actions";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";

const VerifyOtp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSending, isVerifying } = useSelector(selectUser);

  const navigateToRegister = () => {
    navigate("/auth/login");
  };

  const reSendOtp = () => {
    dispatch(sendOtpThunkAction(location.state?.email));
  };

  const formik = useFormik({
    initialValues: {
      otp: ""
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string()
        .required("OTP is Required.")
        .test("len", "Enter Appropriate OTP.", (val) => {
          if (val) return val.toString().length === 4;
        })
    }),
    onSubmit: (values) => {
      dispatch(
        verifyOtpThunkAction(
          { email: location.state?.email, otp: values.otp.toString() },
          navigateToRegister
        )
      );
    }
  });

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div
            style={{
              marginTop: "10px"
            }}
            className="text-danger"
          >
            Code Expired
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              marginTop: "10px",
              color: "white",
              fontSize: "17px",
              fontWeight: "600"
            }}
          >
            Code Expires in
          </div>

          <div style={{ marginTop: "11px" }} className="mx-2 forgot-password">
            {"0" + minutes}:{seconds.toString(10).length === 2 ? seconds : "0" + seconds}
          </div>
        </>
      );
    }
  };
  const { width } = useViewport();
  return (
    <div>
      <div className="limiter">
        <div className="container-register">
          <div className="wrap-register">
            <form className="register-form" onSubmit={formik.handleSubmit}>
              {width > 992 ? (
                ""
              ) : (
                <>
                  <div
                    className={
                      width > 500
                        ? "d-flex justify-content-center px-2 pt-4"
                        : "justify-content-center px-2 pt-4"
                    }
                  >
                    <img
                      src={SherlockLogo}
                      alt="Sherlock Logo"
                      width="180px"
                      className={width > 500 ? "" : "mx-auto d-block"}
                    />
                  </div>
                </>
              )}
              <div
                style={width > 992 ? { marginTop: "65px", marginBottom: "30px" } : {}}
                className="text-welcome text-center py-5"
              >
                Email Address Verfication
              </div>
              <div className={width > 500 ? "pt-1 mx-5" : "pt-1 mx-2"}>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Email Address</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="text"
                      className="form-control form-controlInput"
                      placeholder="Enter Your Email"
                      id="email"
                      name="email"
                      disabled
                      value={location.state?.email}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Enter OTP*</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="number"
                      className="form-control form-controlInput"
                      placeholder="Enter OTP"
                      id="otp"
                      name="otp"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.otp}
                    />
                  </InputGroup>
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="text-danger mt-2">{formik.errors.otp}</div>
                  ) : null}
                </FormGroup>
                <div
                  className={
                    width > 500 ? "d-flex justify-content-start" : "d-flex justify-content-start"
                  }
                >
                  <Countdown date={Date.now() + 180000} renderer={renderer} />
                </div>
              </div>
              <div className={width > 500 ? "text-center mx-5" : "text-center mx-2"}>
                <Button
                  className="loginButton mt-5 w-100"
                  type="submit"
                  disabled={!formik.isValid || isVerifying}
                >
                  <div style={{ fontWeight: "bold" }}>
                    {isVerifying ? "Verifying..." : "Verify & Proceed"}
                  </div>
                </Button>
              </div>
              <div
                className={
                  width > 500
                    ? "d-flex justify-content-start mx-5 mb-5"
                    : "d-flex justify-content-start mx-2 mb-5"
                }
              >
                <div
                  style={{
                    marginTop: "25px",
                    color: "white",
                    fontSize: "17px",
                    fontWeight: "600"
                  }}
                >
                  Didn&apos;t get the Code?
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={reSendOtp}
                  className="mx-2 forgot-password"
                  disabled={isSending}
                >
                  {isSending ? "Sending..." : "Resend"}
                </div>
              </div>
            </form>
            <div className="register-more" style={{ backgroundImage: `url(${RegisterImage})` }}>
              <div className="py-5">
                <div
                  className={
                    width > 1285
                      ? "d-flex justify-content-center px-2"
                      : "justify-content-center px-2"
                  }
                  style={{ marginTop: "-35px" }}
                >
                  <img
                    src={SherlockLogo}
                    alt="Sherlock Logo"
                    width="180px"
                    className={width > 1285 ? "" : "mx-auto d-block"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
