import {
  FETCH_CURRENT_JOB,
  FETCH_CURRENT_JOB_SUCCESS,
  FETCH_CURRENT_JOB_FAILURE,
  ADD_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  JOB_DETAILS,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAILURE,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
  UPDATE_JOB_DETAILS
} from "./types";

const initialState = {
  isLoading: false,
  totalPage: 0,
  error: "",
  isAdding: false,
  currentJobPosting: [],
  jobDetails: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_JOB:
      return { ...state, isLoading: true };

    case FETCH_CURRENT_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentJobPosting: action.payload.currentJobPosting,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_CURRENT_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentJobPosting: [],
        error: action.payload
      };
    case ADD_JOB:
      return {
        ...state,
        isAdding: true
      };
    case ADD_JOB_SUCCESS:
      return {
        ...state,
        isAdding: false
      };
    case ADD_JOB_FAILURE:
      return {
        ...state,
        isAdding: false
      };

    case DELETE_JOB:
      return {
        ...state
      };
    case DELETE_JOB_SUCCESS:
      return {
        ...state
      };
    case DELETE_JOB_FAILURE:
      return {
        ...state
      };

    case JOB_DETAILS:
      return { ...state, isLoading: true };

    case JOB_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobDetails: action.payload.jobDetails,
        error: ""
      };
    case JOB_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobDetails: [],
        error: action.payload
      };
    case UPDATE_JOB:
      return {
        ...state,
        isAdding: true
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        isAdding: false
      };
    case UPDATE_JOB_FAILURE:
      return {
        ...state,
        isAdding: false
      };
    case UPDATE_JOB_DETAILS: {
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          type: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
