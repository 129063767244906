import React from "react";
import { Link } from "react-router-dom";
import userRoles from "../../utils/roles";
import SherlockLogo from "../../assets/img/logo/sidebarLogo.png";
import { Button } from "reactstrap";

const NotFound = () => {
  const role = localStorage.getItem("role");

  const defaultHomePage = () => {
    switch (role) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.ADJUSTER:
        return "/adjuster/current-job-posting";
      default:
        return "/nofound";
    }
  };

  return (
    <>
      <main
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="main-wrapper">
          <div className="error-text">
            <div className="text-center px-2">
              <img src={SherlockLogo} alt="Sherlock Logo" width="30%" />
              <div className="text-center d-flex flex-column mx-3">
                <span style={{ fontSize: "24px", color: "#999FA9" }} className="text-logo mt-3">
                  Catastrophe Inspection
                </span>
              </div>
            </div>
            <div className="text-center my-3">
              <p className="page-title" style={{ justifyContent: "center" }}>
                404 - Page Not Found !
              </p>
              <p style={{ fontSize: "15px", color: "#000" }} className="text-logo mt-3">
                The page you are looking for might be removed or is temporarily unvailable.
              </p>
              <span className="input-group-btn">
                <Link to={defaultHomePage()}>
                  <Button className="registerButton mt-3 w-25">
                    <div style={{ fontWeight: "bold" }}>Go Back</div>
                  </Button>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFound;
