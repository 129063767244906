import { Button, Badge } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";

export const getColumns = (onClickDeleteHandler, onClickViewHandler, onClickCancelViewHandler) => {
  return [
    {
      Header: "Claim No",
      accessor: "claimNumber"
    },
    {
      Header: "Job Title",
      accessor: "title"
    },
    {
      Header: "Insured Name",
      accessor: "ownerName"
    },
    {
      Header: "Phone Number",
      accessor: "mobile",
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.mobile)}</span>;
      }
    },
    {
      Header: "Start Date and Time",
      accessor: "inspectionTime",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.inspectionTime ? (
              <div className="d-flex justify-content-center align-items-center">
                {moment(row.original.inspectionTime).format("D MMM YYYY") +
                  " | " +
                  moment(row.original.inspectionTime).format("LT")}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">-----</div>
            )}
          </span>
        );
      }
    },
    {
      Header: "Completed On",
      accessor: "completedAt",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.completedAt ? (
              <div className="d-flex justify-content-center align-items-center">
                {moment(row.original.completedAt).format("D MMM YYYY") +
                  " | " +
                  moment(row.original.completedAt).format("LT")}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">-----</div>
            )}
          </span>
        );
      }
    },
    {
      Header: "Inspection Type",
      accessor: "type",
      disableSortBy: true
    },
    {
      Header: "Inspection Needed",
      accessor: "inspection",
      disableSortBy: true
    },
    {
      Header: "Assignment Status",
      accessor: "status",
      Cell: ({ row: value }) => {
        if (value.original.jobStatus === "rejected") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#FF0000",
                  backgroundColor: "#FFE6E6",
                  border: "1px solid #BF0000",
                  borderRadius: "12px"
                }}
              >
                Rejected
              </Badge>
            </div>
          );
        } else if (value.original.jobStatus === "pending") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#868686",
                  backgroundColor: "#F9F9F9",
                  border: "1px solid #595959",
                  borderRadius: "12px"
                }}
              >
                Pending
              </Badge>
            </div>
          );
        } else {
          if (value.original.status === "assigned") {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "4%" }}
              >
                <Badge
                  pill
                  style={{
                    color: "#FF7A00",
                    backgroundColor: "#FFF2E6",
                    border: "1px solid #BF5C00",
                    borderRadius: "12px"
                  }}
                >
                  Assigned
                </Badge>
              </div>
            );
          } else if (value.original.status === "inprogress") {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "4%" }}
              >
                <Badge
                  pill
                  style={{
                    color: "#868686",
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #595959",
                    borderRadius: "12px"
                  }}
                >
                  Inprogress
                </Badge>
              </div>
            );
          } else if (value.original.status === "paused") {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "4%" }}
              >
                <Badge
                  pill
                  style={{
                    color: "#0D59B1",
                    backgroundColor: "#CEE0F4",
                    border: "1px solid #0D59B1",
                    borderRadius: "12px"
                  }}
                >
                  Paused
                </Badge>
              </div>
            );
          } else if (value.original.status === "completed") {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "4%" }}
              >
                <Badge
                  pill
                  style={{
                    color: "#0D59B1",
                    backgroundColor: "#CEE0F4",
                    border: "1px solid #0D59B1",
                    borderRadius: "12px"
                  }}
                >
                  Completed
                </Badge>
              </div>
            );
          } else {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "4%" }}
              >
                <Badge
                  pill
                  style={{
                    color: "#FF0000",
                    backgroundColor: "#FFE6E6",
                    border: "1px solid #BF0000",
                    borderRadius: "12px"
                  }}
                >
                  Cancelled
                </Badge>
              </div>
            );
          }
        }
      },
      disableSortBy: true
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              color="primary"
              outline
              style={{ borderRadius: "12px" }}
              onClick={() =>
                row.original.status === "cancelled"
                  ? onClickCancelViewHandler(row?.original?.id)
                  : onClickViewHandler(row?.original?.id)
              }
            >
              View
            </Button>

            <Button
              size="sm"
              color="danger"
              outline
              style={{ borderRadius: "12px" }}
              disabled={row.original.status === "inprogress"}
              onClick={() => onClickDeleteHandler(row?.original?.id)}
            >
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
};
