import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import Search from "../../components/Search/Search";
import useViewport from "../../hooks/useViewport";
import { inspectorListThunkAction, pendingJobsThunkAction } from "../../redux/Admin/actions";
import { selectAdmin } from "../../redux/Admin/selectors";
import { selectUserToken } from "../../redux/User/selectors";
import { getColumns } from "./PendingJobsList";
import { usePagination, useSortBy, useGlobalFilter } from "react-table";
import { useTable } from "react-table";
import Paginate from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import RejectModal from "./RejectModal";
import ApproveModal from "./ApproveModal";
import { useNavigate } from "react-router-dom";
import DropDown from "../../components/DropDown/DropDown";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 5;

const PendingJobs = () => {
  const [error, setError] = React.useState(null);
  const [pendingJob, setPendingJob] = React.useState([]);
  const [sort, setSort] = React.useState({ sortBy: "createdAt", sortDirection: "desc" });
  const [inspector, setInspector] = React.useState([]);
  const [inspectorId, setInspectorId] = React.useState(null);
  const [rejectModalShow, setRejectModalShow] = React.useState(false);
  const [approveModalShow, setApproveModalShow] = React.useState(false);
  const [id, setId] = React.useState("");
  const [globalDate, setGlobalDate] = React.useState({
    end_date: "",
    start_date: ""
  });

  const clearSearch = React.useRef(null);
  const clearDates = React.useRef(null);

  const { width } = useViewport();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const { isLoading, pendingJobs, inspectorList, totalPage } = useSelector(selectAdmin);

  useEffect(() => {
    setPendingJob(pendingJobs);
  }, [pendingJobs, pendingJobs?.length]);

  const onInspectorUpdated = (id, e) => {
    setInspector(() => [
      {
        id,
        value: e
      }
    ]);
  };

  const onRejectHandler = (id) => {
    setRejectModalShow(true);
    setId(id);
    setInspector([]);
  };

  const onApproveHandler = (id, inspector) => {
    setApproveModalShow(true);
    setId(id);
    setInspectorId(inspector[0]?.value?.id);
    setInspector([]);
  };

  const onClickViewHandler = (id) => {
    navigate(`${id}/details`);
  };

  const columns = React.useMemo(
    () =>
      getColumns(
        inspectorList,
        inspector,
        onInspectorUpdated,
        onRejectHandler,
        onApproveHandler,
        onClickViewHandler
      ),
    [inspectorList, inspector]
  );
  const data = React.useMemo(() => pendingJob, [pendingJob]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  useEffect(() => {
    if (token) {
      dispatch(
        pendingJobsThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            sort: sort?.sortBy,
            orderBy: sort?.sortDirection,
            jobStatus: "pending",
            startDate: globalDate?.start_date || "",
            endDate: globalDate?.end_date || ""
          },
          onSuccess,
          onError
        )
      );
      dispatch(
        inspectorListThunkAction({
          role: "inspector"
        })
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort, globalDate]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  const handleValue = (start_date, end_date, type) => {
    setGlobalDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
        <Col xs="12" md="6" className="d-flex align-items-center">
          <span className="dashboard-title">Pending Approval</span>
        </Col>
        <Col xs="6" md="3">
          <DropDown
            handleValue={(start_date, end_date) => handleValue(start_date, end_date, "global")}
            clearDates={clearDates}
          />
        </Col>
        <Col xs="6" md="3">
          <Search
            keyword={globalFilter}
            onSubmitHandler={onSubmitHandler}
            setKeyword={setGlobalFilter}
            clearSearch={clearSearch}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        {isLoading ? (
          <React.Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div className="post_error_loading">{error}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table
                  {...getTableProps()}
                  className="align-items-center table-flush Firms_table_container"
                  responsive
                  bordered
                >
                  <thead className="thead-light Firms_table_header">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((column) => (
                          <th
                            key={column.id}
                            style={!column.disableSortBy ? { cursor: "pointer" } : {}}
                            onClick={() => {
                              if (!column.disableSortBy) {
                                const desc =
                                  column.isSortedDesc === true
                                    ? undefined
                                    : column.isSortedDesc === false
                                    ? true
                                    : false;

                                setSort({
                                  sortBy: column.id,
                                  sortDirection: desc ? "asc" : "desc"
                                });

                                setSortBy([{ id: column.id, desc }]);
                              }
                            }}
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="bi bi-arrow-up mx-2"></i>
                                ) : (
                                  <i className="bi bi-arrow-down mx-2"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell) => {
                            return (
                              <td key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!error && pendingJob?.length === 0 && (
                  <div className="post_error_loading">No Jobs found</div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {isLoading === false && error === null ? (
          <nav aria-label="..." className="post_footer">
            <Paginate
              pageIndex={pageIndex}
              pageCount={pageCount}
              previousPage={previousPage}
              nextPage={nextPage}
              gotoPage={gotoPage}
            />
          </nav>
        ) : null}
      </Row>
      <RejectModal isOpen={rejectModalShow} toggleModal={() => setRejectModalShow(false)} id={id} />
      <ApproveModal
        isOpen={approveModalShow}
        toggleModal={() => setApproveModalShow(false)}
        id={id}
        inspectorId={inspectorId}
      />
    </div>
  );
};

export default PendingJobs;
