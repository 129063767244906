import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import userRoles from "../utils/roles";

const RestrictedAuth = ({ restricted = false }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const defaultHomePage = () => {
    switch (role) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.ADJUSTER:
        return "/adjuster/current-job-posting";
      default:
        return "/admin/dashboard";
    }
  };

  return token && restricted ? (
    <Navigate to={`${defaultHomePage()}`} replace state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export default RestrictedAuth;
