import React, { useEffect } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Row } from "reactstrap";
import Navbar from "../../components/Navbar/Navbar";
import useViewport from "../../hooks/useViewport";
import Flatpickr from "react-flatpickr";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-google-places-autocomplete";

import "../AddJob/AddJob.css";
import "flatpickr/dist/themes/material_blue.css";
import NumberFormat from "react-number-format";
import { JobDetailsThunkAction, updateJobThunkAction } from "../../redux/Adjuster/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectAdjuster } from "../../redux/Adjuster/selectors";
import moment from "moment";
import Loader from "../../components/Loader/Loader";

const EditJobDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { width } = useViewport();
  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = React.useState("");
  const [list, setList] = React.useState([]);
  const [equipementList, setEquipmentList] = React.useState([]);

  useEffect(() => {
    dispatch(JobDetailsThunkAction(jobId));
  }, []);

  const { isLoading, jobDetails, isAdding } = useSelector(selectAdjuster);
  const { id: jobId } = useParams();

  const onSuccess = () => {
    navigate(`/adjuster/current-job-posting/${jobId}/details`);
  };

  function handleChange(event) {
    if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/svg"
    ) {
      setFileError("");
      setFile(event.target.files[0]);
    } else {
      setFileError("Please select image file.");
    }
  }

  const Inspection = [
    { value: "Ladder Assist", label: "Ladder Assist" },
    { value: "Tarp Service with Roof Inspection", label: "Tarp Service with Roof Inspection" },
    { value: "Solo Inspection", label: "Solo Inspection" },
    { value: "Interior Only Inspection", label: "Interior Only Inspection" },
    { value: "Tarp Service", label: "Tarp Service" },
    { value: "ITEL Sample", label: "ITEL Sample" }
  ];

  const SpecialEquipments = [
    { value: "40 Foot Ladder", label: "40 Foot Ladder" },
    { value: "60 Foot Ladder", label: "60 Foot Ladder" },
    { value: "Scissor Lift", label: "Scissor Lift" },
    { value: "Boom Lift", label: "Boom Lift" }
  ];

  useEffect(() => {
    if (jobDetails.length === undefined) {
      setList([]);
      setEquipmentList([]);
      let inspectionArray = jobDetails?.type.split(",");
      const inspectionTypes = inspectionArray.map((item) => {
        let types = { value: item, label: item };
        return types;
      });
      setList(inspectionTypes);
      let equimentArray = jobDetails?.specialEqipment.split(",");
      const equipment = equimentArray.map((item) => {
        let specialEquipment = { value: item, label: item };
        return specialEquipment;
      });
      setEquipmentList(equipment);
    }
  }, [jobDetails]);

  const formik = useFormik({
    initialValues: {
      appointmentReq: false,
      jobTitle: jobDetails?.title ? jobDetails?.title : "",
      date: jobDetails?.inspectionTime ? jobDetails?.inspectionTime : "",
      claimNumber: jobDetails?.claimNumber ? jobDetails?.claimNumber : "",
      ownerName: jobDetails?.ownerName ? jobDetails?.ownerName : "",
      phone: jobDetails?.mobile ? jobDetails?.mobile : "",
      email: jobDetails?.email ? jobDetails?.email : "",
      address: jobDetails?.location ? jobDetails?.location : "",
      latitute: jobDetails?.lat ? jobDetails?.lat : "",
      longitude: jobDetails?.lng ? jobDetails?.lng : "",
      houseNo: jobDetails?.address ? jobDetails?.address : "",
      city: jobDetails?.city ? jobDetails?.city : "",
      state: jobDetails?.state ? jobDetails?.state : "",
      zipcode: jobDetails?.zipCode ? jobDetails?.zipCode : "",
      inspectionType: jobDetails?.type ? jobDetails?.type : "",
      inspection: jobDetails?.inspection ? jobDetails?.inspection : "",
      whatsWrong: jobDetails?.whatsWrong ? jobDetails?.whatsWrong : "",
      equipment: jobDetails?.specialEqipment ? jobDetails?.specialEqipment : ""
    },
    validationSchema: Yup.object().shape({
      jobTitle: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Job Title Required")
        .min(4, "Job Title must be atleast 4 Characters"),
      claimNumber: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Claim Number Required"),
      ownerName: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Owner Name Required"),
      email: Yup.string().email("Email Address is invalid.").required("Email Required"),
      phone: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Phone Number Required")
        .min(10, "Enter Appropriate Phone Number"),
      address: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Address Required"),
      houseNo: Yup.string().trim("Cannot include leading and trailing spaces"),
      city: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("City Required"),
      state: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("State Required"),
      zipcode: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Zipcode Required"),
      inspectionType: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Inspection Type Required"),
      inspection: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Inspection Required"),
      whatsWrong: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Note is required."),
      equipment: Yup.string().trim("Cannot include leading and trailing spaces")
    }),
    onSubmit: (values) => {
      const requestBodyFormData = new FormData();

      requestBodyFormData.append("title", values.jobTitle);
      !values.appointmentReq && requestBodyFormData.append("inspectionTime", values.date);
      requestBodyFormData.append("claimNumber", values.claimNumber);
      requestBodyFormData.append("ownerName", values.ownerName);
      requestBodyFormData.append("email", values.email);
      requestBodyFormData.append("mobile", values.phone);
      requestBodyFormData.append("address", values.houseNo);
      requestBodyFormData.append("city", values.city);
      requestBodyFormData.append("state", values.state);
      requestBodyFormData.append("zipCode", values.zipcode);
      requestBodyFormData.append("location", values.address);
      requestBodyFormData.append("lat", values.latitute);
      requestBodyFormData.append("lng", values.longitude);
      requestBodyFormData.append("type", values.inspectionType);
      requestBodyFormData.append("inspection", values.inspection);
      requestBodyFormData.append("whatsWrong", values.whatsWrong);
      requestBodyFormData.append("specialEqipment", values.equipment);
      requestBodyFormData.append("image", file);

      dispatch(updateJobThunkAction(jobId, requestBodyFormData, onSuccess));
    },
    enableReinitialize: true
  });

  const handleSelect = async (e) => {
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        formik.setFieldValue("address", e.label);
        formik.setFieldValue("latitute", lat);
        formik.setFieldValue("longitude", lng);
      });
    const [place] = await geocodeByPlaceId(e.value.place_id);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) || {};
    const { long_name: city = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_2")) || {};
    const { long_name: state = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};
    formik.setFieldValue("state", state);
    formik.setFieldValue("city", city);
    formik.setFieldValue("zipcode", postalCode);
  };

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Edit Job"} />
      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : (
        <>
          <Card className="my-4" style={{ background: "#0D59B1" }}>
            <CardBody className="card-title">Insured Details</CardBody>
          </Card>
          <Form role="form" onSubmit={formik.handleSubmit}>
            <Card style={{ background: "#FFFFFF" }}>
              <CardBody>
                <Row>
                  <Col xs="12" className="mb-3">
                    <FormGroup className="d-flex justify-content-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="appointmentReq"
                        id="appointmentReq"
                        onChange={(event) => {
                          formik.setFieldValue("appointmentReq", event.target.checked);
                          if (event.target.checked) {
                            formik.setFieldValue("date", null);
                          }
                        }}
                        checked={formik.values.appointmentReq}
                      />
                      <label className="form-check-label mx-2" htmlFor="appointmentReq">
                        Appointment Required
                      </label>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup className="d-flex">
                      <input
                        type="file"
                        className="form-control file_attach"
                        id="file"
                        name="file"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <div className="text-danger pt-2">{fileError}</div>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Job Title"
                          id="jobTitle"
                          name="jobTitle"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.jobTitle}
                        />
                      </InputGroup>
                      {formik.touched.jobTitle && formik.errors.jobTitle ? (
                        <div className="text-danger pt-2">{formik.errors.jobTitle}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup className="mb-3">
                      <InputGroup
                        className="input-group-alternative"
                        disabled={formik.values.appointmentReq}
                      >
                        <Flatpickr
                          value={jobDetails?.inspectionTime}
                          className="form-control"
                          options={{
                            altInput: true,
                            enableTime: true,
                            dateFormat: "Y-m-d",
                            minDate: "today"
                          }}
                          placeholder="Select Date and Time"
                          onChange={(e) => {
                            formik.setFieldValue("date", moment(new Date(e)).utc().format());
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="title mx-1">Basic Details</Row>
                <Row>
                  <Col xs="12" md="3" className="mt-2">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Claim Number"
                          id="claimNumber"
                          name="claimNumber"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.claimNumber}
                        />
                      </InputGroup>
                      {formik.touched.claimNumber && formik.errors.claimNumber ? (
                        <div className="text-danger pt-2">{formik.errors.claimNumber}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="3" className="mt-2">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your House Owner Name"
                          id="ownerName"
                          name="ownerName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ownerName}
                        />
                      </InputGroup>
                      {formik.touched.ownerName && formik.errors.ownerName ? (
                        <div className="text-danger pt-2">{formik.errors.ownerName}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="3" className="mt-2">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <NumberFormat
                          className="form-control"
                          placeholder="Enter Your Phone Number"
                          format="+1(###) ###-####"
                          mask="_"
                          id="phone"
                          name="phone"
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              "phone",
                              value
                                .replace("-", "")
                                .replace("(", "")
                                .replace(")", "")
                                .replace(" ", "")
                                .replace("_", "")
                                .replace("+1", "")
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                      </InputGroup>
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="text-danger pt-2">{formik.errors.phone}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="3" className="mt-2">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Email"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </InputGroup>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger pt-2">{formik.errors.email}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="title mx-1">Address Details</Row>
                <Row>
                  <Col xs="12" md="6" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your House No./Street/Landmark"
                          id="houseNo"
                          name="houseNo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.houseNo}
                        />
                      </InputGroup>
                      {formik.touched.houseNo && formik.errors.houseNo ? (
                        <div className="text-danger pt-2">{formik.errors.houseNo}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="2" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your City"
                          id="city"
                          name="city"
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                        />
                      </InputGroup>
                      {formik.touched.city && formik.errors.city ? (
                        <div className="text-danger pt-2">{formik.errors.city}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="2" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your State"
                          id="state"
                          name="state"
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                        />
                      </InputGroup>
                      {formik.touched.state && formik.errors.state ? (
                        <div className="text-danger pt-2">{formik.errors.state}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="2" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Zipcode"
                          id="zipcode"
                          name="zipcode"
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zipcode}
                        />
                      </InputGroup>
                      {formik.touched.zipcode && formik.errors.zipcode ? (
                        <div className="text-danger pt-2">{formik.errors.zipcode}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="mt-2">
                    <GooglePlacesAutocomplete
                      className="form-control"
                      id="address"
                      name="address"
                      apiKey={process.env.REACT_APP_GCP_KEY}
                      selectProps={{
                        placeholder: "Select Location",
                        onChange: (e) => {
                          handleSelect(e);
                        },
                        defaultInputValue: formik.values.address
                      }}
                    />
                  </Col>
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-danger pt-2">{formik.errors.address}</div>
                  ) : null}
                </Row>
              </CardBody>
            </Card>
            <Card className="my-4" style={{ background: "#0D59B1" }}>
              <CardBody className="card-title">Adjuster Details</CardBody>
            </Card>
            <Card style={{ background: "#FFFFFF" }}>
              <CardBody>
                <Row className="title mx-1">Inspection Details</Row>
                <Row>
                  <Col xs="12" md="6" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Select
                          isMulti
                          name="inspectionType"
                          id="inspectionType"
                          options={Inspection}
                          placeholder="Select Inspection Type"
                          className="input-select"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            let element = "";
                            for (let index = 0; index < e.length; index++) {
                              if (element) {
                                element = element + "," + e[index].value;
                              } else {
                                element = e[index].value;
                              }
                            }
                            formik.setFieldValue("inspectionType", element);
                          }}
                          defaultValue={list}
                        />
                      </InputGroup>
                      {formik.touched.inspectionType && formik.errors.inspectionType ? (
                        <div className="text-danger pt-2">{formik.errors.inspectionType}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Inspection Needed"
                          id="inspection"
                          name="inspection"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.inspection}
                        />
                      </InputGroup>
                      {formik.touched.inspection && formik.errors.inspection ? (
                        <div className="text-danger pt-2">{formik.errors.inspection}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          id="whatsWrong"
                          name="whatsWrong"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.whatsWrong}
                        />
                      </InputGroup>
                      {formik.touched.whatsWrong && formik.errors.whatsWrong ? (
                        <div className="text-danger pt-2">{formik.errors.whatsWrong}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" className="mt-3">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Select
                          isMulti
                          name="equipment"
                          id="equipment"
                          options={SpecialEquipments}
                          placeholder="Select Special Equipment needed"
                          className="input-select"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            let element = "";
                            for (let index = 0; index < e.length; index++) {
                              if (element) {
                                element = element + "," + e[index].value;
                              } else {
                                element = e[index].value;
                              }
                            }
                            formik.setFieldValue("equipment", element);
                          }}
                          defaultValue={equipementList}
                        />
                      </InputGroup>
                      {formik.touched.equipment && formik.errors.equipment ? (
                        <div className="text-danger pt-2">{formik.errors.equipment}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Button
              disabled={!formik.isValid || isAdding}
              type="submit"
              style={width > 992 ? { width: "250px" } : {}}
              className="registerButton mt-4"
            >
              <div style={{ fontWeight: "bold" }}>{isAdding ? "Updating.." : "Update Job"}</div>
            </Button>
          </Form>
        </>
      )}
    </div>
  );
};

export default EditJobDetails;
