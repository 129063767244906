import { Button, Badge } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";

export const getColumns = () => {
  return [
    {
      Header: "Claim No",
      accessor: "claimNumber"
    },
    {
      Header: "Insured Name",
      accessor: "ownerName"
    },
    {
      Header: "Phone Number",
      accessor: "mobile",
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.mobile)}</span>;
      }
    },
    {
      Header: "Started",
      accessor: "inspectionTime",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.inspectionTime ? (
              <div>
                {moment(row.original.inspectionTime).format("D MMM YYYY") +
                  " | " +
                  moment(row.original.inspectionTime).format("LT")}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">-----</div>
            )}
          </span>
        );
      },
      disableSortBy: true
    },
    {
      Header: "Inspection Type",
      accessor: "type",
      disableSortBy: true
    },
    {
      Header: "Inspector",
      accessor: "inspector",
      Cell: ({ row }) => {
        return <span>{row?.original?.inspector?.fullName}</span>;
      },
      disableSortBy: true
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: value }) => {
        if (value.original.status === "completed") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#0D59B1",
                  backgroundColor: "#CEE0F4",
                  border: "1px solid #0D59B1",
                  borderRadius: "12px"
                }}
              >
                Completed
              </Badge>
            </div>
          );
        } else {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge
                pill
                style={{
                  color: "#FF0000",
                  backgroundColor: "#FFE6E6",
                  border: "1px solid #BF0000",
                  borderRadius: "12px"
                }}
              >
                Cancelled
              </Badge>
            </div>
          );
        }
      }
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row: value }) => {
        return (
          <div className="d-flex justify-content-around">
            <a href={value.original.reportUrl} target="_blank" rel="noreferrer">
              <Button
                size="sm"
                disabled={!value.original.reportUrl}
                color="danger"
                outline
                style={{ borderRadius: "20px" }}
              >
                Pdf Report
              </Button>
            </a>
          </div>
        );
      }
    }
  ];
};
