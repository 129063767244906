import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useViewport from "../../hooks/useViewport";
import ActiveJobs from "./ActiveJobs";
import PendingJobs from "./PendingJobs";

const JobList = () => {
  const { width } = useViewport();
  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Job List"} />
      <PendingJobs />
      <ActiveJobs />
    </div>
  );
};

export default JobList;
