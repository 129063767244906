import React from "react";
import RequireAuth from "../components/RequireAuth";
import { Route, Routes } from "react-router-dom";
import { toggleContext } from "../App";
import { adjusterSidebarRoutes } from "../data/sidebar";
import userRoles from "../utils/roles";

import Sidebar from "../components/Sidebar/Sidebar";
import CurrentJobPosting from "../pages/JobPosting/CurrentJobPosting";
import NotFound from "../pages/NotFound/NotFound";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import AddJob from "../pages/AddJob/AddJob";
import JobDetails from "../pages/JobDetails/JobDetails";
import CancelledJobDetails from "../pages/JobDetails/CancelledJobDetails";
import Profile from "../pages/Profile/Profile";
import EditJobDetails from "../pages/JobDetails/EditJobDetails";
import InspectorReporting from "../pages/InspectorReporting/InspectorReporting";
import NewPassword from "../pages/ForgotPassword/NewPassword";

const AdjusterLayout = () => {
  const [isopen, setisOpen] = React.useState(true);
  return (
    <>
      <toggleContext.Provider
        value={{
          isopen,
          setisOpen
        }}
      >
        <Sidebar routes={adjusterSidebarRoutes} />
        <div className={isopen ? "sidecontainer" : "sidecontainerclose"}>
          <div>
            <Routes>
              <Route element={<RequireAuth allowedRoles={[userRoles.ADJUSTER]} />}>
                <Route path="current-job-posting" element={<CurrentJobPosting />} />
                <Route path="current-job-posting/:id/details" element={<JobDetails />} />
                <Route
                  path="current-job-posting/:id/cancelledDetails"
                  element={<CancelledJobDetails />}
                />
                <Route path="current-job-posting/:id/edit" element={<EditJobDetails />} />
                <Route path="post-new-job" element={<AddJob />} />
                <Route path="inspector-reporting" element={<InspectorReporting />} />
                <Route path="resetpassword" element={<NewPassword />} />
                <Route path="profile" element={<Profile />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </div>
      </toggleContext.Provider>
    </>
  );
};

export default AdjusterLayout;
