import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";

function DropDown({ clearDates, direction = "down", handleValue, ...args }) {
  const [customDateRange, setCustomDateRange] = useState(null);

  React.useEffect(() => {
    clearDates.current = () => setCustomDateRange(null);
  }, []);

  return (
    <div className="">
      <Flatpickr
        required={true}
        value={customDateRange}
        options={{
          mode: "range",
          dateFormat: "m-d-Y",
          disableMobile: true,
          allowInput: true,
          position: "below right",

          onClose: (selectedDates) => {
            if (selectedDates?.length === 2) {
              setCustomDateRange(selectedDates);
              handleValue(
                moment.utc(moment(selectedDates[0]).startOf("day").format()).format(),
                moment.utc(moment(selectedDates[1]).endOf("day").format()).format()
              );
            }
          }
        }}
        className="flatpickr datetimepicker form-control"
        placeholder="MM-DD-YYYY"
      />
    </div>
  );
}

DropDown.propTypes = {
  direction: PropTypes.string
};

export default DropDown;
