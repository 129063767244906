import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import toast from "react-hot-toast";
import {
  activeJobsThunkAction,
  inspectorListThunkAction,
  pendingJobsThunkAction,
  rejectJobThunkAction
} from "../../redux/Admin/actions";

const RejectModal = ({ toggleModal, isOpen, id }) => {
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const onRejectJob = () => {
    dispatch(
      pendingJobsThunkAction(
        {
          jobStatus: "pending",
          limit: 5
        },
        onSuccess,
        onError
      )
    );
    dispatch(
      inspectorListThunkAction({
        role: "inspector"
      })
    );
    dispatch(
      activeJobsThunkAction(
        {
          jobStatus: "approved",
          status: "assigned,inprogress",
          limit: 10
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Reject Job</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalBody>Do you really want to Reject the Job ? Once done Cannot be undone.</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              const requestBody = { jobStatus: "rejected", inspectorId: null };
              dispatch(rejectJobThunkAction(id, requestBody, onRejectJob));
              toggleModal();
            }}
          >
            Reject
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RejectModal;
