import { Button, Form, FormGroup, Input, InputGroup } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";
import Select from "react-select";
import { useState } from "react";
import { Icon } from "@iconify/react";
import DatePicker from "../../components/DatePicker/DatePicker";

export const getColumns = (
  inspectorList,
  inspector,
  onInspectorUpdated,
  onRejectHandler,
  onApproveHandler,
  onClickViewHandler
) => {
  const inspectorArray = inspectorList.map((item) => {
    return {
      id: item.id,
      value:
        item.id +
        " - " +
        item.fullName +
        " - " +
        (+item.assignedJobsCount + +item.inProgressJobsCount),
      label: item.fullName + " - " + (+item.assignedJobsCount + +item.inProgressJobsCount)
    };
  });
  return [
    {
      Header: "Claim No",
      accessor: "claimNumber"
    },
    {
      Header: "Insured Name",
      accessor: "ownerName"
    },
    {
      Header: "Phone Number",
      accessor: "mobile",
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.mobile)}</span>;
      }
    },
    {
      Header: "Date and Time",
      accessor: "inspectionTime",
      Cell: ({ row }) => {
        const date =
          moment(row.original.inspectionTime).format("D MMM YYYY") +
          " | " +
          moment(row.original.inspectionTime).format("LT");

        const [isEditable, setIsEditable] = useState(false);

        return (
          <>
            {!isEditable ? (
              <span>
                {row.original.inspectionTime ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div>{date}</div>
                    <div>
                      <Icon
                        icon="tabler:edit"
                        className="mx-3"
                        onClick={() => setIsEditable(!isEditable)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    -----
                    <div>
                      <Icon
                        icon="tabler:edit"
                        className="mx-3"
                        onClick={() => setIsEditable(!isEditable)}
                      />
                    </div>
                  </div>
                )}
              </span>
            ) : (
              <DatePicker
                setIsEditable={setIsEditable}
                isActiveJob={false}
                id={row?.original?.id}
                inspectionTime={row?.original?.inspectionTime}
                claimNumber={row?.original?.claimNumber}
              />
            )}
          </>
        );
      }
    },
    {
      Header: "Adjuster Name",
      accessor: "user",
      Cell: ({ row }) => {
        return <span>{row.original.user?.fullName}</span>;
      },
      disableSortBy: true
    },
    {
      Header: "Inspection Type",
      accessor: "type",
      disableSortBy: true
    },
    {
      Header: "Select Inspector",
      accessor: "id",
      Cell: ({ row }) => {
        return (
          <Select
            name={row.original.id}
            id={row.original.id}
            options={inspectorArray}
            placeholder="Select Inspector"
            className="input-select"
            menuPosition="fixed"
            onChange={(e) => {
              onInspectorUpdated(row?.original?.id, e);
            }}
            value={inspector.find((isn) => isn?.id === row.original.id)?.value}
          />
        );
      },
      disableSortBy: true
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              style={{
                border: "1px solid #FF7A00",
                borderRadius: "20px",
                background: " #FF7A00",
                color: "#ffffff"
              }}
              onClick={() => onRejectHandler(row?.original?.id)}
            >
              Reject
            </Button>

            <Button
              size="sm"
              style={{
                border: "1px solid #0D59B1",
                borderRadius: "20px",
                background: "#0D59B1",
                color: "#ffffff"
              }}
              disabled={!inspector.find((isn) => isn?.id === row.original.id)}
              onClick={() => onApproveHandler(row?.original?.id, inspector)}
            >
              Approve
            </Button>
          </div>
        );
      }
    },
    {
      Header: "Details",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              color="primary"
              outline
              style={{ borderRadius: "12px" }}
              onClick={() => onClickViewHandler(row?.original?.id)}
            >
              View
            </Button>
          </div>
        );
      }
    }
  ];
};
