import React from "react";
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import usePasswordShow from "../../hooks/usePasswordShow";
import useViewport from "../../hooks/useViewport";
import { useFormik } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/User/selectors";

import RegisterImage from "../../assets/img/backgroundImages/registerImage.png";
import SherlockLogo from "../../assets/img/logo/sherlockHome.png";
import showPasswordIcon from "../../assets/img/theme/showPassword.png";
import hidePasswordIcon from "../../assets/img/theme/hidePassword.png";

import "../Register/AdjusterRegistration.css";
import { useDispatch, useSelector } from "react-redux";
import { registerAdjusterThunkAction } from "../../redux/User/actions";

const AdjusterRegisteration = () => {
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();
  const { confirmPasswordShown, toggleConfirmPasswordVisibility, type1 } = usePasswordShow();
  const { width } = useViewport();

  const { isSigning } = useSelector(selectUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");

  const onSuccess = () => {
    navigate("/auth/verifyOtp", { state: { email: email } });
  };

  const formik = useFormik({
    initialValues: {
      adjusterName: "",
      phone: "",
      companyName: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object().shape({
      adjusterName: Yup.string()
        .trim("Cannot include leading and trailing spaces.")
        .required("Adjuster Name is Required.")
        .min(4, "Adjuster Name must be atleast 4 Characters."),
      phone: Yup.string()
        .trim("Cannot include leading and trailing spaces.")
        .required("Phone Number is Required.")
        .min(10, "Enter Appropriate Phone Number."),
      companyName: Yup.string()
        .trim("Cannot include leading and trailing spaces.")
        .required("Insurance Carrier is Required.")
        .min(4, "Insurance Carrier must be atleast 4 Characters."),
      email: Yup.string().email("Email Address is invalid.").required("Email is Required."),
      password: Yup.string()
        .required("Password is Required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
          "Password Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
        ),
      confirmPassword: Yup.string()
        .required("Confirm Password is Required.")
        .oneOf([Yup.ref("password"), null], "Password and Confirm Password Does Not Match.")
    }),
    onSubmit: (values) => {
      const requestBodyFormData = new FormData();

      requestBodyFormData.append("fullName", values.adjusterName);
      requestBodyFormData.append("email", values.email);
      requestBodyFormData.append("mobile", values.phone);
      requestBodyFormData.append("role", "adjuster");
      requestBodyFormData.append("password", values.password);
      requestBodyFormData.append("companyName", values.companyName);

      dispatch(registerAdjusterThunkAction(requestBodyFormData, onSuccess));
    }
  });

  return (
    <div>
      <div className="limiter">
        <div className="container-register">
          <div className="wrap-register">
            <form className="register-form" autoComplete="off">
              {width > 992 ? (
                ""
              ) : (
                <div
                  className={
                    width > 500
                      ? "d-flex justify-content-center align-items-center px-2 pt-4"
                      : "justify-content-center px-2 pt-4"
                  }
                >
                  <img
                    src={SherlockLogo}
                    alt="Sherlock Logo"
                    width="180px"
                    className={width > 500 ? "" : "mx-auto d-block"}
                  />
                </div>
              )}
              <div className={width > 500 ? "pt-5 mx-5" : "pt-5 mx-2"}>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Adjuster Name*</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="text"
                      className="form-control form-controlInput"
                      placeholder="Enter Adjuster Name"
                      id="adjusterName"
                      name="adjusterName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.adjusterName}
                    />
                  </InputGroup>
                </FormGroup>
                {formik.touched.adjusterName && formik.errors.adjusterName ? (
                  <div className="text-danger">{formik.errors.adjusterName}</div>
                ) : null}
              </div>
              <div className={width > 500 ? "pt-1 mx-5" : "pt-1 mx-2"}>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Phone Number*</label>
                  <InputGroup className="input-group-alternative">
                    <NumberFormat
                      className="form-control form-controlInput"
                      placeholder="Enter your Phone Number"
                      format="+1(###) ###-####"
                      mask="_"
                      id="phone"
                      name="phone"
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          "phone",
                          value
                            .replace("-", "")
                            .replace("(", "")
                            .replace(")", "")
                            .replace(" ", "")
                            .replace("_", "")
                            .replace("+1", "")
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                  </InputGroup>
                </FormGroup>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger mt-2">{formik.errors.phone}</div>
                ) : null}
              </div>
              <div className={width > 500 ? "pt-1 mx-5" : "pt-1 mx-2"}>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Insurance Carrier*</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="text"
                      className="form-control form-controlInput"
                      placeholder="Enter Your Insurance Carrier"
                      id="companyName"
                      name="companyName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyName}
                    />
                  </InputGroup>
                </FormGroup>
                {formik.touched.companyName && formik.errors.companyName ? (
                  <div className="text-danger mt-2">{formik.errors.companyName}</div>
                ) : null}
              </div>
              <div className={width > 500 ? "pt-1 mx-5" : "pt-1 mx-2"}>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Email Address*</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="text"
                      className="form-control form-controlInput"
                      placeholder="Enter Your Email"
                      id="email"
                      name="email"
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                        setEmail(e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </InputGroup>
                </FormGroup>
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger mt-2">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className={width > 500 ? "pt-2 mx-5" : "pt-2 mx-2"}>
                <FormGroup className="mb-3">
                  <label className="py-2 labelInput">Password*</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control containerNumberinput"
                      placeholder="Enter Your Password"
                      type={type}
                      autoComplete="password"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />

                    <InputGroupAddon
                      addonType="append"
                      style={{ cursor: "pointer", opacity: "0.9" }}
                      onClick={togglePasswordVisiblity}
                    >
                      <InputGroupText className="password_icon">
                        {passwordShown ? (
                          <img
                            style={{ height: "20px" }}
                            alt="hidepassword"
                            src={hidePasswordIcon}
                          />
                        ) : (
                          <img
                            style={{ height: "20px" }}
                            alt="showpassword"
                            src={showPasswordIcon}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-danger mt-2">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className={width > 500 ? "pt-2 pb-4 mx-5" : "pt-2 pb-4 mx-2"}>
                <FormGroup>
                  <label className="py-2 labelInput">Confirm Password*</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control containerNumberinput"
                      placeholder="Enter Your Confirm Password"
                      type={type1}
                      autoComplete="confirmpassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />
                    <InputGroupAddon
                      addonType="append"
                      style={{ cursor: "pointer", opacity: "0.9" }}
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      <InputGroupText className="password_icon">
                        {confirmPasswordShown ? (
                          <img
                            style={{ height: "20px" }}
                            alt="hidepassword"
                            src={hidePasswordIcon}
                          />
                        ) : (
                          <img
                            style={{ height: "20px" }}
                            alt="showpassword"
                            src={showPasswordIcon}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="text-danger my-3">{formik.errors.confirmPassword}</div>
                ) : null}
              </div>
            </form>
            <div className="register-more" style={{ backgroundImage: `url(${RegisterImage})` }}>
              <div className="py-5">
                <div
                  className={
                    width > 1285
                      ? "d-flex justify-content-center px-2"
                      : "justify-content-center px-2"
                  }
                  style={{ marginTop: "-35px" }}
                >
                  <img
                    src={SherlockLogo}
                    alt="Sherlock Logo"
                    width="180px"
                    className={width > 1285 ? "" : "my-4 mx-auto d-block"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            width > 500
              ? "d-flex flex-wrap justify-content-end"
              : "d-flex flex-wrap justify-content-center"
          }
          style={width > 992 ? { marginRight: "160px" } : {}}
        >
          <div
            style={{
              marginTop: "25px",
              color: "#868686",
              fontSize: "17px",
              fontWeight: "600"
            }}
          >
            Already have an account?
          </div>
          <NavLink to="/auth/login" tag={Link}>
            <div className="mx-2 forgot-password">LOG IN</div>
          </NavLink>

          <div className={width > 500 ? "text-center mx-5" : "text-center mx-2 w-100"}>
            <Button
              disabled={!formik.isValid || isSigning}
              style={width > 992 ? { width: "250px" } : {}}
              className="registerButton my-3"
              onClick={formik.handleSubmit}
            >
              <div style={{ fontWeight: "bold" }}>
                {" "}
                {isSigning ? "Registering..." : "Create Account"}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjusterRegisteration;
