import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useViewport from "../../hooks/useViewport";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import "./Dashboard.css";
import DashboardImage from "../../assets/img/logo/dashboardImage.png";
import JobImage from "../../assets/img/logo/jobImage.png";
import AdminImage from "../../assets/img/sidebarIcon/adminImage.png";
import ActiveAdjuster from "../../assets/img/sidebarIcon/activeAdjuster.png";
import ActiveInspector from "../../assets/img/sidebarIcon/ActiveInspector.png";
import ActiveJobList from "../../assets/img/sidebarIcon/ActiveJobList.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAdmin } from "../../redux/Admin/selectors";
import { useEffect } from "react";
import { dashboardDetailsThunkAction } from "../../redux/Admin/actions";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { width } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, dashboardDetails } = useSelector(selectAdmin);

  useEffect(() => {
    dispatch(dashboardDetailsThunkAction());
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const redirectJobList = () => {
    navigate("/admin/job-list");
  };

  const redirectJobDetails = (id) => {
    navigate(`/admin/job-list/${id}/details`);
  };

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Welcome Back!"} />
      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : (
        <>
          <Card className="mt-4">
            <CardBody className="card-body dashboard">
              <div style={{ background: "#dae4ee", borderRadius: "6px", height: "22vh" }}>
                <div className={width > 1024 ? "p-5" : "p-5"}>
                  <span className="dashboard-title">Catastrophe Dashboard</span>
                  <img
                    className={width > 878 ? "position-absolute top-0 end-0" : "d-none"}
                    src={DashboardImage}
                    alt="Dashboard Image"
                  />
                </div>
                <div>
                  <img src={AdminImage} alt="Admin Image" className={"admin-image"} />
                </div>
              </div>
              <div
                className="p-5"
                style={width > 1024 ? { height: "15vh" } : { height: "100%", marginTop: "-30px" }}
              >
                <Row>
                  <Col lg="3">
                    <span
                      style={width > 786 ? {} : { marginLeft: "130px" }}
                      className="dashboard-title"
                    >
                      Admin
                    </span>
                    <div
                      style={width > 786 ? {} : { marginLeft: "130px" }}
                      className="dashboard-email"
                    >
                      admin@gmail.com
                    </div>
                  </Col>
                  <Col lg="3">
                    <Card className={width > 1024 ? "dashboard-card mx-4" : "dashboard-card my-3"}>
                      <CardBody className="card-body dashboard m-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span className="cardTitle">Completed Jobs</span>
                            <div className="cardCount">
                              {dashboardDetails?.completedJobsCount?.toString().padStart(4, "0")}
                            </div>
                          </div>
                          <div>
                            <img
                              src={ActiveJobList}
                              alt="Job List"
                              width="50px"
                              style={{ marginTop: "-5px" }}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className={width > 1024 ? "dashboard-card mx-4" : "dashboard-card my-3"}>
                      <CardBody className="card-body dashboard m-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span className="cardTitle">On-Board Adjusters</span>
                            <div className="cardCount">
                              {dashboardDetails?.adjusterCount?.toString().padStart(4, "0")}
                            </div>
                          </div>
                          <div>
                            <img
                              src={ActiveAdjuster}
                              alt="Job List"
                              width="45px"
                              style={{ marginTop: "-5px" }}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className={width > 1024 ? "dashboard-card mx-4" : "dashboard-card my-3"}>
                      <CardBody className="card-body dashboard m-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span className="cardTitle">On-Board Inspectors</span>
                            <div className="cardCount">
                              {dashboardDetails?.inspectorCount?.toString().padStart(4, "0")}
                            </div>
                          </div>
                          <div>
                            <img
                              src={ActiveInspector}
                              alt="Job List"
                              width="50px"
                              style={{ marginTop: "-5px" }}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardBody>
              <div className="d-flex align-items-center justify-content-between">
                <div className="card-label">Job Updates</div>
                <Button className="viewAllLabel" onClick={redirectJobList}>
                  View All<i className="bi bi-arrow-right mx-2"></i>
                </Button>
              </div>
              <Row className={width > 786 ? "mt-3 mx-3 mb-2" : "mt-3 mb-2"}>
                {dashboardDetails?.recentPendingJobs &&
                  dashboardDetails?.recentPendingJobs.map((item) => (
                    <Col lg="4" className="mt-3" key={item.id}>
                      <div className="jobListCard">
                        <div>
                          {item.imageUrl && (
                            <img
                              src={item.imageUrl}
                              alt="Job Image"
                              width="100%"
                              height="150px"
                              style={{ objectFit: "cover", borderRadius: "24px" }}
                            />
                          )}
                          {!item.imageUrl && (
                            <img
                              src={JobImage}
                              alt="Job Image"
                              width="100%"
                              height="150px"
                              style={{ objectFit: "cover", borderRadius: "24px" }}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-between mt-2 mx-2">
                          <div>
                            <span className="jobTitle">{item?.title}</span>{" "}
                            <span className="claimNumber mx-2">{"#" + item?.claimNumber}</span>
                          </div>
                          <div className="status">{capitalizeFirstLetter(item?.jobStatus)}</div>
                        </div>
                        <div className="d-flex justify-content-between mt-2 mx-2">
                          <div>
                            <span className="inspectionType">{item?.type}</span>
                          </div>
                          <div className="time">
                            {item?.inspectionTime &&
                              moment(item?.inspectionTime).format("D MMM YYYY") +
                                " | " +
                                moment(item?.inspectionTime).format("LT")}
                          </div>
                        </div>
                        <div className="mt-2 mx-2 adjuster">Adj - {item?.user?.fullName}</div>
                        <div className="mt-2 mx-2 address">
                          {item?.address +
                            " , " +
                            item?.city +
                            " , " +
                            item?.state +
                            " , " +
                            item?.zipCode}
                        </div>
                        <div className="mt-2 mx-2 ">
                          <Button
                            className="viewAllLabel"
                            onClick={() => redirectJobDetails(item?.id)}
                          >
                            View Details
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))}

                {dashboardDetails?.recentPendingJobs &&
                  dashboardDetails?.recentPendingJobs.length === 0 && (
                    <div className="post_error_loading">No Pending Jobs Found</div>
                  )}
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default Dashboard;
