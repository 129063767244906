import toast from "react-hot-toast";

import {
  fetchProfileApi,
  forgotPasswordApi,
  LogInUserApi,
  LogOutUserApi,
  registerAdjusterApi,
  resetPasswordApi,
  sendOtpApi,
  updateProfileApi,
  verifyOtpApi
} from "../../services/userServices";

import {
  SET_IS_SIGNING,
  RESET_IS_SIGNING,
  SET_SENDING_OTP,
  RESET_SENDING_OTP,
  SET_VERIFYING_OTP,
  RESET_VERIFYING_OTP,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE
} from "./types";

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING
  };
};

export const sendOtp = () => {
  return {
    type: SET_SENDING_OTP
  };
};
export const resetOtp = () => {
  return {
    type: RESET_SENDING_OTP
  };
};

export const verifyOtp = () => {
  return {
    type: SET_VERIFYING_OTP
  };
};
export const resetVerifyOtp = () => {
  return {
    type: RESET_VERIFYING_OTP
  };
};

export const setLoggedInUser = ({ user, token, role }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token, role }
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER
  };
};

export const fetchProfile = () => {
  return {
    type: FETCH_PROFILE
  };
};

export const fetchProfileSuccess = (adjusterProfile) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: { adjusterProfile }
  };
};

export const fetchProfileFailure = (error) => {
  return {
    type: FETCH_PROFILE_FAILURE,
    payload: error
  };
};

export const updateProfile = () => {
  return {
    type: UPDATE_PROFILE
  };
};

export const updateProfileSuccess = () => {
  return {
    type: UPDATE_PROFILE_SUCCESS
  };
};

export const updateProfileFailure = () => {
  return {
    type: UPDATE_PROFILE_FAILURE
  };
};

export const registerAdjusterThunkAction = (details, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());

      const promise = registerAdjusterApi(details);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Adjuster Registered Successfully",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};

export const sendOtpThunkAction = (email) => {
  return async (dispatch) => {
    try {
      dispatch(sendOtp());
      const { data } = await sendOtpApi({
        email: email
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetOtp());
    } catch (error) {
      dispatch(resetOtp());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const verifyOtpThunkAction = ({ email, otp }, navigateToLogin) => {
  return async (dispatch) => {
    try {
      dispatch(verifyOtp());
      const { data } = await verifyOtpApi({
        email: email,
        otp: otp
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetVerifyOtp());
      navigateToLogin();
    } catch (error) {
      dispatch(resetVerifyOtp());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const forgotPasswordThunkAction = ({ email, redirectUrl }) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await forgotPasswordApi({
        email: email,
        redirectUrl: redirectUrl
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const resetPasswordThunkAction = (requestBody, navigateToLogin) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const details = {
        email: requestBody.email,
        otp: requestBody.otp,
        password: requestBody.password
      };
      const promise = resetPasswordApi(details);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Password Reset successful.",
        error: (error) =>
          error.response.status
            ? "Unauthorized to reset password."
            : `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      localStorage.removeItem("token");
      navigateToLogin();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};

export const LoginUserThunkAction = (requestBody, redirectToVerify) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());

      const { data } = await LogInUserApi({
        email: requestBody.email,
        password: requestBody.password,
        role: ["admin", "adjuster"]
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }

      if (data.success === true && data.data.user.status === false) {
        toast.success("Please Verify Yourself.");
        redirectToVerify();
      } else {
        toast.success("Logged in successfully.");
        localStorage.setItem("token", data.data.accessToken);
        localStorage.setItem("role", data.data.user.role);
        localStorage.setItem("user", JSON.stringify(data.data.user));

        dispatch(
          setLoggedInUser({
            user: data.data.user,
            token: data.data.accessToken,
            role: data.data.user.role
          })
        );
      }

      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const LogOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await LogOutUserApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }

      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("user");
      dispatch(setLoggedOutUser());
      toast.success("Logged out successfully");
      onSuccess();
    } catch (error) {
      localStorage.removeItem("token");
      dispatch(setLoggedOutUser());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchProfileThunkAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProfile());
      const { data } = await fetchProfileApi(id);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(fetchProfileSuccess(data?.data));
    } catch (error) {
      dispatch(fetchProfileFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateProfileThunkAction = (requestBodyFormData, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      dispatch(updateProfile());
      const { data } = await updateProfileApi(requestBodyFormData);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(updateProfileSuccess());

      toast.success("Profile Updated.");
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(updateProfileFailure());
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};
