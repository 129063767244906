import React, { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";
import Navbar from "../../components/Navbar/Navbar";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Row } from "reactstrap";
import SherlockLogo from "../../assets/img/logo/jobImage.png";
import "./JobDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { selectAdjuster } from "../../redux/Adjuster/selectors";
import { useNavigate, useParams } from "react-router-dom";
import {
  JobDetailsThunkAction,
  updateJobDetails,
  updateJobThunkAction
} from "../../redux/Adjuster/actions";
import Loader from "../../components/Loader/Loader";
import { formatPhoneNumber } from "../../utils/helper";
import DeleteJobModal from "../JobPosting/DeleteJobModal";
import moment from "moment";
import { Icon } from "@iconify/react";
import Select from "react-select";

const Inspection = [
  { value: "Ladder Assist", label: "Ladder Assist" },
  { value: "Tarp Service with Roof Inspection", label: "Tarp Service with Roof Inspection" },
  { value: "Solo Inspection", label: "Solo Inspection" },
  { value: "Interior Only Inspection", label: "Interior Only Inspection" },
  { value: "Tarp Service", label: "Tarp Service" },
  { value: "ITEL Sample", label: "ITEL Sample" }
];

const JobDetails = () => {
  const { width } = useViewport();
  const { isLoading, jobDetails, isAdding } = useSelector(selectAdjuster);

  const [isEditable, setIsEditable] = useState(false);
  const [inspectionType, setInspectionType] = useState("");

  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const { id: jobId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(JobDetailsThunkAction(jobId));
  }, []);

  useEffect(() => {
    if (jobDetails) {
      setInspectionType(jobDetails?.type);
    }
  }, [jobDetails]);

  const redirectEdit = () => {
    navigate(`/adjuster/current-job-posting/${jobId}/edit`);
  };

  const redirectBack = () => {
    navigate("/admin/job-list");
  };

  const role = localStorage.getItem("role");

  const onSuccess = () => {
    dispatch(updateJobDetails(inspectionType));
    setIsEditable(false);
    setInspectionType("");
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (!inspectionType.trim()) {
      return;
    }

    const requestBodyFormData = new FormData();

    requestBodyFormData.append("type", inspectionType);
    requestBodyFormData.append("claimNumber", jobDetails.claimNumber);

    dispatch(updateJobThunkAction(jobId, requestBodyFormData, onSuccess));
  };

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Job Details"} />
      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : width > 1100 ? (
        <Card className="mt-4" style={{ background: "#ffffff" }}>
          <CardBody>
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <div className="d-flex">
                <div style={{ width: "50%" }}>
                  <Col
                    xs="12"
                    lg="12"
                    md="12"
                    className="mb-4"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <Row className="imageStyles" style={{ position: "relative" }}>
                      <img
                        className="my-2"
                        src={jobDetails?.imageUrl ? jobDetails?.imageUrl : `${SherlockLogo}`}
                      />
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Start Date and Time
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {moment(jobDetails?.inspectionTime).format("D MMM YYYY") +
                          " | " +
                          moment(jobDetails?.inspectionTime).format("LT")}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Job Title
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.title}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Claim Number
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.claimNumber}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Insured name
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.ownerName}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Phone number
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {formatPhoneNumber(jobDetails?.mobile)}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        House / Street
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        <div className="my-3">{jobDetails?.address}</div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        City / Zipcode
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.city + ", " + jobDetails?.zipCode}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        State
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.state}
                      </Col>
                    </Row>
                  </Col>
                </div>
                <div
                  style={{
                    borderLeft: "2px solid #D1D1D1",
                    height: "100%",
                    marginLeft: "50px",
                    width: "50%"
                  }}
                >
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Adjuster Name
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.user?.fullName}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Type of Inspection
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {!isEditable ? (
                          <div>
                            {jobDetails?.type}

                            <Icon
                              icon="tabler:edit"
                              className="mx-3"
                              onClick={() => setIsEditable(!isEditable)}
                            />
                          </div>
                        ) : (
                          <Form onSubmit={submitHandler}>
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Select
                                  isMulti
                                  name="inspectionType"
                                  id="inspectionType"
                                  options={Inspection}
                                  placeholder="Select Inspection Type*"
                                  className="input-select"
                                  value={Inspection.filter((item) => {
                                    return inspectionType.split(",").includes(item.value);
                                  })}
                                  onChange={(e) => {
                                    let element = "";
                                    for (let index = 0; index < e.length; index++) {
                                      if (element) {
                                        element = element + "," + e[index].value;
                                      } else {
                                        element = e[index].value;
                                      }
                                    }
                                    setInspectionType(element);
                                  }}
                                />
                              </InputGroup>
                              <button
                                type="submit"
                                className="btn btn-primary mt-2"
                                disabled={isAdding}
                              >
                                Submit
                              </button>

                              <button
                                type="button"
                                className="btn btn-secondary mx-2 mt-2"
                                onClick={() => {
                                  setIsEditable(false);
                                  setInspectionType(jobDetails?.type);
                                }}
                              >
                                Cancel
                              </button>
                            </FormGroup>
                          </Form>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex  align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Inspection Needed
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.inspection}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        Special Equipments
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.specialEqipment}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="d-flex align-items-center label"
                        xs="5"
                        md="5"
                        lg="5"
                      >
                        What’s going on with the house?
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {jobDetails?.whatsWrong}
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card className="mt-4" style={{ background: "#ffffff" }}>
          <CardBody>
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col xs="12" lg="12" md="12" className="mb-4" style={{ textAlign: "-webkit-center" }}>
                <Row className="imageStyles" style={{ position: "relative" }}>
                  <img
                    className="my-2"
                    src={jobDetails?.imageUrl ? jobDetails?.imageUrl : `${SherlockLogo}`}
                  />
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Job Title
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.title}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Claim Number
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.claimNumber}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex  align-items-center label" xs="5" md="5" lg="5">
                    Insured name
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.ownerName}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Start Date and Time
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {moment(jobDetails?.inspectionTime).format("D MMM YYYY") +
                      " | " +
                      moment(jobDetails?.inspectionTime).format("LT")}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Phone number
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {formatPhoneNumber(jobDetails?.mobile)}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    House / Street
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.address}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    City / Zipcode
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.city + ", " + jobDetails?.zipCode}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    State
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.state}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Adjuster Name
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.user?.fullName}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Type of Inspection
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {!isEditable ? (
                      <div className="my-3">
                        {jobDetails?.type}

                        <Icon
                          icon="tabler:edit"
                          className="mx-3"
                          onClick={() => setIsEditable(!isEditable)}
                        />
                      </div>
                    ) : (
                      <Form onSubmit={submitHandler}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <Select
                              isMulti
                              name="inspectionType"
                              id="inspectionType"
                              options={Inspection}
                              placeholder="Select Inspection Type*"
                              className="input-select"
                              value={Inspection.filter((item) => {
                                return inspectionType.split(",").includes(item.value);
                              })}
                              onChange={(e) => {
                                let element = "";
                                for (let index = 0; index < e.length; index++) {
                                  if (element) {
                                    element = element + "," + e[index].value;
                                  } else {
                                    element = e[index].value;
                                  }
                                }
                                setInspectionType(element);
                              }}
                            />
                          </InputGroup>
                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                            disabled={isAdding}
                          >
                            Submit
                          </button>

                          <button
                            type="button"
                            className="btn btn-secondary mx-2 mt-2"
                            onClick={() => {
                              setIsEditable(false);
                              setInspectionType(jobDetails?.type);
                            }}
                          >
                            Cancel
                          </button>
                        </FormGroup>
                      </Form>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Inspection Needed
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.inspection}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    Special Equipments
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.specialEqipment}
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="12" md="12" className="mb-4">
                <Row>
                  <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                    What’s going on with the house?
                  </Col>
                  <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                    :
                  </Col>
                  <Col className="value" xs="6" md="6" lg="6">
                    {jobDetails?.whatsWrong}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {!isLoading && role === "adjuster" && jobDetails?.status === "assigned" ? (
        <Card className="mt-2" style={{ background: "#FFFFFF" }}>
          <CardBody>
            <div className="d-flex">
              <Button
                size={width > 786 ? "md" : "sm"}
                className="deleteButton"
                onClick={() => {
                  setDeleteModalShow(true);
                }}
              >
                Delete Job
              </Button>

              <Button
                size={width > 786 ? "md" : "sm"}
                className="mx-4 registerButton"
                onClick={redirectEdit}
              >
                Edit Job
              </Button>
            </div>
          </CardBody>
        </Card>
      ) : (
        ""
      )}

      {!isLoading && role === "admin" ? (
        <Card className="mt-2" style={{ background: "#FFFFFF" }}>
          <CardBody>
            <div className="d-flex">
              <Button
                size={width > 786 ? "md" : "sm"}
                className="mx-4 registerButton"
                onClick={redirectBack}
              >
                Back to Job List
              </Button>
            </div>
          </CardBody>
        </Card>
      ) : (
        ""
      )}

      <DeleteJobModal
        isOpen={deleteModalShow}
        toggleModal={() => setDeleteModalShow(false)}
        id={jobId}
        navigate={true}
      />
    </div>
  );
};

export default JobDetails;
