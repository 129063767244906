import axiosInstance from "./axiosInstance";

export const currentJobPostingApi = (requestBody) =>
  axiosInstance.post(`api/job/list`, requestBody);

export const addJobApi = (userData) => axiosInstance.post("api/job/create", userData);

export const deleteJobApi = (id) => axiosInstance.delete(`api/job/delete/${id}`);

export const jobDetailsApi = (id) => axiosInstance.get(`api/job/${id}`);

export const updateJobApi = (id, userData) => axiosInstance.post(`api/job/update/${id}`, userData);

export const deleteAdjusterApi = (id) =>
  axiosInstance.delete(`api/users/delete-user-account/${id}`);
