import { Button } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import InspectorImage from "../../assets/img/sidebarIcon/InspectorProfile.png";
import { Icon } from "@iconify/react";

export const getColumns = (deleteInspectorHandler) => {
  return [
    {
      Header: "Profile Picture",
      accessor: "id",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={row?.original?.profileImage ? row?.original?.profileImage : InspectorImage}
              alt="Inspector Profile Image"
              height="35px"
              width="35px"
              style={{ borderRadius: "20px" }}
            />
          </div>
        );
      },
      disableSortBy: true
    },
    {
      Header: "Inspector Name",
      accessor: "fullName"
    },

    {
      Header: "Phone Number",
      accessor: "mobile",
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.mobile)}</span>;
      }
    },
    {
      Header: "Email Address",
      accessor: "email"
    },
    {
      Header: "In Progress Jobs",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              style={{
                border: "1px solid #ff7a00",
                borderRadius: "12px",
                background: "#ffeedf",
                color: "#ff7a00"
              }}
            >
              {row?.original?.inProgressJobsCount}
            </Button>
          </div>
        );
      }
    },
    {
      Header: "Completed Jobs",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              style={{
                border: "1px solid #0d59b1",
                borderRadius: "12px",
                background: "#cee0f4",
                color: "#0d59b1"
              }}
            >
              {row?.original?.completedJobsCount}
            </Button>
          </div>
        );
      }
    },
    {
      Header: "Delete",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around align-items-center mt-2">
            <Icon
              icon="ic:baseline-delete"
              color="red"
              width={24}
              height={24}
              style={{
                cursor: "pointer"
              }}
              onClick={() => deleteInspectorHandler(row?.original?.id)}
            />
          </div>
        );
      }
    }
  ];
};
