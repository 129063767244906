import {
  DASHBOARD_DETAILS,
  DASHBOARD_DETAILS_SUCCESS,
  DASHBOARD_DETAILS_FAILURE,
  PENDING_JOBS,
  PENDING_JOBS_SUCCESS,
  PENDING_JOBS_FAILURE,
  INSPECTOR_LIST,
  INSPECTOR_LIST_SUCCESS,
  INSPECTOR_LIST_FAILURE,
  ADJUSTER_LIST,
  ADJUSTER_LIST_SUCCESS,
  ADJUSTER_LIST_FAILURE,
  REJECT_JOB,
  REJECT_JOB_SUCCESS,
  REJECT_JOB_FAILURE,
  APPROVE_JOB,
  APPROVE_JOB_SUCCESS,
  APPROVE_JOB_FAILURE,
  ACTIVE_JOBS,
  ACTIVE_JOBS_SUCCESS,
  ACTIVE_JOBS_FAILURE,
  JOB_REPORTING,
  JOB_REPORTING_SUCCESS,
  JOB_REPORTING_FAILURE,
  PENDING_JOB_UPDATE,
  ACTIVE_JOB_UPDATE
} from "./types";
import toast from "react-hot-toast";
import {
  getDashboardDetailsApi,
  jobListApi,
  updateJobStatusApi,
  usersListApi
} from "../../services/adminServices";
import { deleteAdjusterApi } from "../../services/adjusterServices";

export const dashboardDetails = () => {
  return {
    type: DASHBOARD_DETAILS
  };
};

export const dashboardDetailsSuccess = (dashboardDetails, totalPage) => {
  return {
    type: DASHBOARD_DETAILS_SUCCESS,
    payload: { dashboardDetails, totalPage }
  };
};

export const dashboardDetailsFailure = () => {
  return {
    type: DASHBOARD_DETAILS_FAILURE
  };
};

export const pendingJobs = () => {
  return {
    type: PENDING_JOBS
  };
};

export const pendingJobsSuccess = (pendingJobs, totalPage) => {
  return {
    type: PENDING_JOBS_SUCCESS,
    payload: { pendingJobs, totalPage }
  };
};

export const pendingJobsFailure = () => {
  return {
    type: PENDING_JOBS_FAILURE
  };
};

export const pendingJobUpdate = (id, date) => {
  return {
    type: PENDING_JOB_UPDATE,
    payload: { id, date }
  };
};

export const activeJobs = () => {
  return {
    type: ACTIVE_JOBS
  };
};

export const activeJobsSuccess = (activeJobs, totalPages) => {
  return {
    type: ACTIVE_JOBS_SUCCESS,
    payload: { activeJobs, totalPages }
  };
};

export const activeJobsFailure = () => {
  return {
    type: ACTIVE_JOBS_FAILURE
  };
};

export const activeJobUpdate = (id, date) => {
  return {
    type: ACTIVE_JOB_UPDATE,
    payload: { id, date }
  };
};

export const jobReporting = () => {
  return {
    type: JOB_REPORTING
  };
};

export const jobReportingSuccess = (jobReporting, totalPage) => {
  return {
    type: JOB_REPORTING_SUCCESS,
    payload: { jobReporting, totalPage }
  };
};

export const jobReportingFailure = () => {
  return {
    type: JOB_REPORTING_FAILURE
  };
};

export const inspectorList = () => {
  return {
    type: INSPECTOR_LIST
  };
};

export const inspectorListSuccess = (inspectorList, inspectorPage) => {
  return {
    type: INSPECTOR_LIST_SUCCESS,
    payload: { inspectorList, inspectorPage }
  };
};

export const inspectorListFailure = () => {
  return {
    type: INSPECTOR_LIST_FAILURE
  };
};

export const adjusterList = () => {
  return {
    type: ADJUSTER_LIST
  };
};

export const adjusterListSuccess = (adjusterList, totalPage) => {
  return {
    type: ADJUSTER_LIST_SUCCESS,
    payload: { adjusterList, totalPage }
  };
};

export const adjusterListFailure = () => {
  return {
    type: ADJUSTER_LIST_FAILURE
  };
};

export const rejectJob = () => {
  return {
    type: REJECT_JOB
  };
};

export const rejectJobSuccess = () => {
  return {
    type: REJECT_JOB_SUCCESS
  };
};

export const rejectJobFailure = () => {
  return {
    type: REJECT_JOB_FAILURE
  };
};

export const approveJob = () => {
  return {
    type: APPROVE_JOB
  };
};

export const approveJobSuccess = () => {
  return {
    type: APPROVE_JOB_SUCCESS
  };
};

export const approveJobFailure = () => {
  return {
    type: APPROVE_JOB_FAILURE
  };
};

export const dashboardDetailsThunkAction = () => {
  return async (dispatch) => {
    try {
      dispatch(dashboardDetails());
      const { data } = await getDashboardDetailsApi();

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(dashboardDetailsSuccess(data?.data));
    } catch (error) {
      dispatch(dashboardDetailsFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const pendingJobsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(pendingJobs());
      const { data } = await jobListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(pendingJobsSuccess(data?.data?.jobs, data?.data?.jobsCount / params?.limit));
      onSuccess();
    } catch (error) {
      dispatch(pendingJobsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const activeJobsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(activeJobs());
      const { data } = await jobListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(activeJobsSuccess(data?.data?.jobs, data?.data?.jobsCount / params?.limit));
      onSuccess();
    } catch (error) {
      dispatch(activeJobsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const jobReportingThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(jobReporting());
      const { data } = await jobListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(jobReportingSuccess(data?.data?.jobs, data?.data?.jobsCount / params?.limit));
      onSuccess();
    } catch (error) {
      dispatch(jobReportingFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const inspectorListThunkAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(inspectorList());
      const { data } = await usersListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(inspectorListSuccess(data?.data?.user, data?.data?.userCount / params?.limit));
    } catch (error) {
      dispatch(inspectorListFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const adjusterListThunkAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(adjusterList());
      const { data } = await usersListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(adjusterListSuccess(data?.data?.user, data?.data?.userCount / params?.limit));
    } catch (error) {
      dispatch(adjusterListFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const rejectJobThunkAction = (id, requestBody, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(rejectJob());
      const { data } = await updateJobStatusApi(id, requestBody);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(rejectJobSuccess());
      toast.success("Job Rejected Successfully");
      onSuccess();
    } catch (error) {
      dispatch(rejectJobFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const approveJobThunkAction = (id, requestBody, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(approveJob());
      const { data } = await updateJobStatusApi(id, requestBody);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(approveJobSuccess());
      toast.success("Job Approved Successfully");
      onSuccess();
    } catch (error) {
      dispatch(approveJobFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const deleteInspectorThunkAction = (id, onDelete) => {
  return async () => {
    try {
      const promise = deleteAdjusterApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Inspector deleted successfully.",
        error: (error) =>
          `${error?.response?.data?.message || error?.message || "Internal server Error."}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onDelete();
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};
