import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import useViewport from "../../hooks/useViewport";
import DummyUser from "../../assets/img/sidebarIcon/dummyUser.png";
import { Button, Card, CardBody, Col, FormGroup, Input, InputGroup, Row } from "reactstrap";
import "./Profile.css";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { selectUser } from "../../redux/User/selectors";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber } from "../../utils/helper";
import Loader from "../../components/Loader/Loader";
import NumberFormat from "react-number-format";
import { fetchProfileThunkAction, updateProfileThunkAction } from "../../redux/User/actions";

const Profile = () => {
  const { width } = useViewport();
  const [edit, setEdit] = React.useState(false);
  const [fileError, setFileError] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState(null);

  const dispatch = useDispatch();

  const { isLoading, isSigning, adjusterProfile } = useSelector(selectUser);

  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  useEffect(() => {
    dispatch(fetchProfileThunkAction(user.id));
  }, []);

  const formik = useFormik({
    initialValues: {
      adjusterName: adjusterProfile?.fullName,
      email: adjusterProfile?.email,
      phone: adjusterProfile?.mobile,
      companyName: adjusterProfile?.companyName
    },
    validationSchema: Yup.object().shape({
      adjusterName: Yup.string()
        .required("Adjuster Name is Required.")
        .min(4, "Adjuster Name must be atleast 4 Characters."),
      companyName: Yup.string()
        .required("Insurance Carrier is Required.")
        .min(4, "Insurance Carrier must be atleast 4 Characters."),
      email: Yup.string().email("Email Address is invalid.").required("Email is Required."),
      phone: Yup.string()
        .required("Phone Number is Required.")
        .min(10, "Enter Appropriate Phone Number.")
    }),
    onSubmit: (values) => {
      const requestBodyFormData = new FormData();
      if (file) {
        requestBodyFormData.append("fullName", values.adjusterName);
        requestBodyFormData.append("mobile", values.phone);
        requestBodyFormData.append("companyName", values.companyName);
        requestBodyFormData.append("profileImage", file);
      } else {
        requestBodyFormData.append("fullName", values.adjusterName);
        requestBodyFormData.append("mobile", values.phone);
        requestBodyFormData.append("companyName", values.companyName);
      }
      dispatch(updateProfileThunkAction(requestBodyFormData, onSuccess));
    },
    enableReinitialize: true
  });

  const onSuccess = () => {
    dispatch(fetchProfileThunkAction(user.id));
    setEdit(false);
  };

  function handleChange(event) {
    if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/svg"
    ) {
      setFileError("");
      setFile(event.target.files[0]);
      setUrl(URL.createObjectURL(event.target.files[0]));
    } else {
      setFileError("Please select image file.");
    }
  }

  return (
    <div className={width > 786 ? "p-3" : "p-1"}>
      <Navbar title={"Profile Details"} />
      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : width > 1318 ? (
        <>
          <Card className="my-4" style={{ background: "#ffffff" }}>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Row className="px-lg-5 px-md-4 px-3 py-3">
                  <Col md="8" xs="12">
                    <Row md="3" xs="3">
                      {adjusterProfile?.profileImage && (
                        <>
                          <img
                            className="imageStyle"
                            src={url ? url : `${adjusterProfile?.profileImage}`}
                            alt="Dummy User"
                            style={
                              width > 786
                                ? { height: "180px", width: "200px", marginTop: "30px" }
                                : {}
                            }
                          />
                        </>
                      )}
                      {!adjusterProfile?.profileImage && (
                        <>
                          <img
                            className="imageStyle"
                            src={url ? url : DummyUser}
                            alt="Dummy User"
                            style={
                              width > 786
                                ? { height: "180px", width: "200px", marginTop: "30px" }
                                : {}
                            }
                          />
                        </>
                      )}
                      {edit ? (
                        <>
                          <label
                            htmlFor="file"
                            style={{
                              position: "absolute",
                              top: "47px",
                              left: "220px",
                              display: "flex",
                              justifyContent: "flex-end",
                              backgroundColor: "#0d59b1",
                              width: "40px",
                              height: "41px",
                              borderRadius: "20px",
                              cursor: "pointer"
                            }}
                          >
                            <i className="mt-2 text-white bi bi-pencil-square"></i>
                          </label>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="file"
                            accept="image/*"
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        ""
                      )}

                      <div
                        className={width > 786 ? "mx-4 my-5" : "mx-2 my-4"}
                        style={{ width: "60%" }}
                      >
                        <div className={edit ? "" : "my-4"}>
                          <span className="label">Adjuster Name :</span>
                          {edit ? (
                            <React.Fragment>
                              <FormGroup className="my-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Adjuster Name"
                                  id="adjusterName"
                                  name="adjusterName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.adjusterName}
                                />
                              </FormGroup>
                              {formik.touched.adjusterName && formik.errors.adjusterName ? (
                                <div className="text-danger mt-2">{formik.errors.adjusterName}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <span className="value mx-3">{adjusterProfile?.fullName}</span>
                          )}
                        </div>
                        <div className={edit ? "" : "my-4"}>
                          <span className="label">Email Id :</span>
                          {edit ? (
                            <React.Fragment>
                              <FormGroup className="my-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Email"
                                  id="email"
                                  name="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  disabled
                                />
                              </FormGroup>
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger mt-2">{formik.errors.email}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <span className="value mx-3">{adjusterProfile?.email}</span>
                          )}
                        </div>
                      </div>
                    </Row>
                    <div className="text-danger pt-2">{fileError}</div>
                  </Col>
                  <Col md="7" xs="12" className={width > 786 ? "mt-4" : "mt-2"}>
                    <span className="label">Phone Number :</span>
                    {edit ? (
                      <React.Fragment>
                        <NumberFormat
                          className="form-control my-2"
                          format="+1(###) ###-####"
                          mask="_"
                          placeholder="Enter Your Phone Number"
                          id="phone"
                          name="phone"
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              "phone",
                              value
                                .replace("-", "")
                                .replace("(", "")
                                .replace(")", "")
                                .replace(" ", "")
                                .replace("_", "")
                                .replace("+1", "")
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="text-danger mt-2">{formik.errors.phone}</div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <span className="value mx-3">
                        {formatPhoneNumber(adjusterProfile?.mobile)}
                      </span>
                    )}
                  </Col>
                  <Col md="7" xs="12" className="mt-3">
                    <span className="label">Insurance Carier :</span>
                    {edit ? (
                      <React.Fragment>
                        <FormGroup className="my-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Insurance Carrier"
                            id="companyName"
                            name="companyName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                          />
                        </FormGroup>
                        {formik.touched.companyName && formik.errors.companyName ? (
                          <div className="text-danger mt-2">{formik.errors.companyName}</div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <span className="value mx-3">{adjusterProfile?.companyName}</span>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-end">
                {edit && (
                  <Button
                    size="md"
                    color="primary"
                    outline
                    disabled={!formik.isValid || isSigning || !edit}
                    onClick={formik.handleSubmit}
                  >
                    <Icon icon="dashicons:update-alt" style={{ marginRight: "4px" }} />
                    {isSigning ? "Updating" : "Update Details"}
                  </Button>
                )}

                {!edit && (
                  <Button
                    size="md"
                    color="secondary"
                    outline
                    disabled={edit}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <i style={{ marginRight: "4px" }} className="bi bi-pencil-square" />
                    Edit
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <Card className="my-4" style={{ background: "#ffffff" }}>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Row className="px-lg-5 px-md-4 px-3 py-3">
                  <Col
                    xs="12"
                    lg="12"
                    md="12"
                    className="mb-4"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <Row className="imageStyle" style={{ position: "relative" }}>
                      {adjusterProfile?.profileImage && (
                        <img
                          src={url ? url : `${adjusterProfile?.profileImage}`}
                          alt="Dummy User"
                        />
                      )}
                      {!adjusterProfile?.profileImage && (
                        <img src={url ? url : DummyUser} alt="Dummy User" />
                      )}

                      {edit ? (
                        <>
                          <label
                            htmlFor="file"
                            style={{
                              position: "absolute",
                              top: "-14px",
                              left: "160px",
                              display: "flex",
                              justifyContent: "flex-end",
                              backgroundColor: "#0d59b1",
                              width: "40px",
                              height: "41px",
                              borderRadius: "20px",
                              cursor: "pointer"
                            }}
                          >
                            <i className="mt-2 text-white bi bi-pencil-square"></i>
                          </label>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="file"
                            accept="image/*"
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <div className="text-danger pt-2">{fileError}</div>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Adjuster Name
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {edit ? (
                          <React.Fragment>
                            <FormGroup>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Adjuster Name"
                                id="adjusterName"
                                name="adjusterName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adjusterName}
                              />
                            </FormGroup>
                            {formik.touched.adjusterName && formik.errors.adjusterName ? (
                              <div className="text-danger mt-2">{formik.errors.adjusterName}</div>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <div>{adjusterProfile?.fullName}</div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Email Id
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {edit ? (
                          <React.Fragment>
                            <FormGroup>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your Email"
                                id="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                disabled
                              />
                            </FormGroup>
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-danger mt-2">{formik.errors.email}</div>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <div>{adjusterProfile?.email}</div>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Phone Number
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {edit ? (
                          <React.Fragment>
                            <FormGroup>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Phone Number"
                                id="phone"
                                name="phone"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  formik.setFieldValue(
                                    "phone",
                                    value
                                      .replace("-", "")
                                      .replace("(", "")
                                      .replace(")", "")
                                      .replace(" ", "")
                                      .replace("_", "")
                                      .replace("+1", "")
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                              />
                            </FormGroup>
                            {formik.touched.phone && formik.errors.phone ? (
                              <div className="text-danger mt-2">{formik.errors.phone}</div>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <div>{formatPhoneNumber(adjusterProfile?.mobile)}</div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="12" md="12" className="mb-4">
                    <Row>
                      <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                        Insurance Carrier
                      </Col>
                      <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                        :
                      </Col>
                      <Col className="value" xs="6" md="6" lg="6">
                        {edit ? (
                          <React.Fragment>
                            <FormGroup>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Insurance Carrier"
                                id="companyName"
                                name="companyName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.companyName}
                              />
                            </FormGroup>
                            {formik.touched.companyName && formik.errors.companyName ? (
                              <div className="text-danger mt-2">{formik.errors.companyName}</div>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <div>{adjusterProfile?.companyName}</div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-end">
                {edit && (
                  <Button
                    size="sm"
                    color="primary"
                    outline
                    disabled={!formik.isValid || isSigning || !edit}
                    onClick={formik.handleSubmit}
                  >
                    <Icon icon="dashicons:update-alt" style={{ marginRight: "4px" }} />
                    {isSigning ? "Updating" : "Update Details"}
                  </Button>
                )}

                {!edit && (
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    disabled={edit}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <i style={{ marginRight: "4px" }} className="bi bi-pencil-square" />
                    Edit
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default Profile;
