import axiosInstance from "./axiosInstance";

export const registerAdjusterApi = (userData) => axiosInstance.post("/api/auth/register", userData);

export const sendOtpApi = (userData) => axiosInstance.post("/api/auth/resend-email", userData);

export const verifyOtpApi = (userData) => axiosInstance.post("/api/auth/verify-otp", userData);

export const forgotPasswordApi = (userData) =>
  axiosInstance.post("/api/auth/forgot-password", userData);

export const resetPasswordApi = (userData) =>
  axiosInstance.post("/api/auth/reset-password", userData);

export const LogInUserApi = (userData) => axiosInstance.post("/api/auth/login", userData);

export const LogOutUserApi = () => axiosInstance.get("/api/auth/logout");

export const fetchProfileApi = (id) => axiosInstance.get(`api/users/get-profile/${id}`);

export const updateProfileApi = (userData) =>
  axiosInstance.post("api/users/update-profile", userData);
