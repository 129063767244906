import React from "react";
import RequireAuth from "../components/RequireAuth";
import { Route, Routes } from "react-router-dom";
import { toggleContext } from "../App";
import adminSidebarRoutes from "../data/sidebar";
import userRoles from "../utils/roles";

import Sidebar from "../components/Sidebar/Sidebar";
import Dashboard from "../pages/Dashboard/Dashboard";
import NotFound from "../pages/NotFound/NotFound";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import JobList from "../pages/JobList/JobList";
import JobDetails from "../pages/JobDetails/JobDetails";
import Inspector from "../pages/ManageStaff/Inspector";
import Adjuster from "../pages/ManageStaff/Adjuster";
import JobReporting from "../pages/JobReporting/JobReporting";
import CancelledJobDetails from "../pages/JobDetails/CancelledJobDetails";
import NewPassword from "../pages/ForgotPassword/NewPassword";

const AdminLayout = () => {
  const [isopen, setisOpen] = React.useState(true);
  return (
    <>
      <toggleContext.Provider
        value={{
          isopen,
          setisOpen
        }}
      >
        <Sidebar routes={adminSidebarRoutes} />
        <div className={isopen ? "sidecontainer" : "sidecontainerclose"}>
          <div>
            <Routes>
              <Route element={<RequireAuth allowedRoles={[userRoles.ADMIN]} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="job-list" element={<JobList />} />
                <Route path="job-list/:id/details" element={<JobDetails />} />
                <Route path="job-list/:id/cancelledDetails" element={<CancelledJobDetails />} />
                <Route path="manage-staff/inspector-list" element={<Inspector />} />
                <Route path="manage-staff/adjuster-list" element={<Adjuster />} />
                <Route path="job-reporting" element={<JobReporting />} />
                <Route path="resetpassword" element={<NewPassword />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </div>
      </toggleContext.Provider>
    </>
  );
};

export default AdminLayout;
