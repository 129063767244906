import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, InputGroup } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import { activeJobUpdate, pendingJobUpdate } from "../../redux/Admin/actions";
import { useDispatch } from "react-redux";
import { updateJobThunkAction } from "../../redux/Adjuster/actions";

function DatePicker({ setIsEditable, inspectionTime, id, isActiveJob, claimNumber }) {
  const [dateTime, setDateTime] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inspectionTime) {
      setDateTime(inspectionTime);
    }
  }, [inspectionTime]);

  const onSuccess = () => {
    if (isActiveJob) {
      dispatch(activeJobUpdate(id, dateTime));
    } else {
      dispatch(pendingJobUpdate(id, dateTime));
    }

    setIsEditable(false);
    setDateTime(null);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (!dateTime || dateTime === "Invalid date") {
      return;
    }

    const requestBodyFormData = new FormData();

    requestBodyFormData.append("inspectionTime", dateTime);
    requestBodyFormData.append("claimNumber", claimNumber);

    dispatch(updateJobThunkAction(id, requestBodyFormData, onSuccess));
  };

  return (
    <>
      <div>
        <Form onSubmit={submitHandler} className="d-flex">
          <FormGroup className="">
            <InputGroup className="input-group-alternative">
              <Flatpickr
                value={dateTime}
                className="form-control"
                options={{
                  altInput: true,
                  enableTime: true,
                  dateFormat: "Y-m-d",
                  minDate: "today"
                }}
                placeholder="Select Date and Time"
                onChange={(e) => {
                  setDateTime(moment(new Date(e)).utc().format());
                }}
              />
            </InputGroup>
          </FormGroup>
          <button type="submit" className="btn btn-primary mx-1">
            <Icon
              icon="streamline:interface-calendar-check-approve-calendar-check-date-day-month-success"
              width={15}
              height={15}
            />
          </button>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setIsEditable(false);
              setDateTime(null);
            }}
          >
            <Icon icon="streamline:interface-calendar-disable-calendar-date-day-delete-disable-month-remove" />
          </button>
        </Form>
      </div>
    </>
  );
}

export default DatePicker;
