import React from "react";
import Sidebaritems from "../Sidebar/SidebarItems";
import { Icon } from "@iconify/react";
import "./Sidebar.css";
import useViewport from "../../hooks/useViewport";
import { Button } from "reactstrap";
import { toggleContext } from "../../App";
import SidebarLogo from "../../assets/img/logo/sidebarLogo.png";

const Sidebar = (props) => {
  const { routes } = props;
  const { width } = useViewport();
  const { isopen, setisOpen } = React.useContext(toggleContext);
  return (
    <React.Fragment>
      {width >= 785 && (
        <div className={isopen ? "sidebar" : "sidebarclose"}>
          <div>
            <span className="d-flex justify-content-between align-items-center">
              <span className={isopen ? "mt-4 mb-2 ml-2" : "d-none"}>
                <p
                  className="mt-2"
                  style={{
                    letterSpacing: "1px",
                    marginLeft: "30px",
                    fontWeight: "bolder",
                    color: "#ffffff",
                    fontSize: "19px",
                    fontFamily: "Ibarra Real Nova"
                  }}
                >
                  Catastrophe Inspection
                </p>
              </span>
              {isopen ? (
                <Icon
                  icon="material-symbols:keyboard-double-arrow-left-rounded"
                  className="m-3 px-1"
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={() => setisOpen(!isopen)}
                  width="50px"
                />
              ) : (
                <Icon
                  icon="material-symbols:keyboard-double-arrow-right-rounded"
                  className="m-3 px-1"
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={() => setisOpen(!isopen)}
                  width="50px"
                />
              )}
            </span>
          </div>

          {routes?.map((item, index) => (
            <Sidebaritems key={index} item={item} isopen={isopen} />
          ))}

          {isopen && (
            <img
              src={SidebarLogo}
              alt="Sidebar Logo"
              width="100%"
              className="position-absolute bottom-0"
              style={{ marginBottom: "15px", zIndex: "-1" }}
            />
          )}
        </div>
      )}
      {width < 787 && (
        <div className={isopen ? "sidebar" : "sidebarclose-active"}>
          <div>
            <span className="d-flex justify-content-between align-items-center">
              <span className={isopen ? "mt-4 ml-2" : "d-none"}>
                <p
                  className="mt-2"
                  style={{
                    letterSpacing: "1px",
                    marginLeft: "30px",
                    fontWeight: "bolder",
                    color: "#ffffff",
                    fontFamily: "Ibarra Real Nova"
                  }}
                >
                  Catastrophe Inspection
                </p>
              </span>
              <div onClick={() => setisOpen(!isopen)}>
                {isopen ? (
                  <Icon
                    icon="material-symbols:keyboard-double-arrow-left-rounded"
                    className="m-3 px-1"
                    style={{ cursor: "pointer", color: "white" }}
                    onClick={() => setisOpen(!isopen)}
                    width="35px"
                  />
                ) : (
                  <Button style={{ position: "absolute", zIndex: 9999 }} className="m-1 px-1">
                    <i className="bi bi-list mx-2" />
                  </Button>
                )}
              </div>
            </span>
          </div>

          {routes?.map((item, index) => (
            <Sidebaritems key={index} item={item} isopen={isopen} />
          ))}

          {isopen && (
            <img
              src={SidebarLogo}
              alt="Sidebar Logo"
              width="100%"
              className="position-absolute bottom-0"
              style={{ marginBottom: "15px", zIndex: "-1" }}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
