import {
  SET_IS_SIGNING,
  RESET_IS_SIGNING,
  SET_SENDING_OTP,
  RESET_SENDING_OTP,
  SET_VERIFYING_OTP,
  RESET_VERIFYING_OTP,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE
} from "./types";

const initialState = {
  loggedInUser: null,
  token: null,
  role: "",
  isLoading: false,
  isSigning: false,
  error: "",
  isVerifying: false,
  isSending: false,
  adjusterProfile: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SIGNING:
      return {
        ...state,
        isSigning: true
      };
    case RESET_IS_SIGNING:
      return {
        ...state,
        isSigning: false
      };
    case SET_SENDING_OTP:
      return {
        ...state,
        isSending: true
      };
    case RESET_SENDING_OTP:
      return {
        ...state,
        isSending: false
      };
    case SET_VERIFYING_OTP:
      return {
        ...state,
        isVerifying: true
      };
    case RESET_VERIFYING_OTP:
      return {
        ...state,
        isVerifying: false
      };
    case SET_LOGGEDIN_USER:
      return {
        ...state,
        token: action.payload.token,
        loggedInUser: action.payload.user,
        role: action.payload.roleId,
        error: "",
        isSigning: false
      };
    case SET_LOGGEDOUT_USER:
      return {
        ...state,
        ...initialState
      };

    case FETCH_PROFILE:
      return { ...state, isLoading: true };

    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adjusterProfile: action.payload.adjusterProfile,
        error: ""
      };
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        adjusterProfile: [],
        error: action.payload
      };
    case UPDATE_PROFILE:
      return {
        ...state
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default reducer;
